import React, { useContext, useEffect, useState, useCallback, useRef } from 'react';
import Context from '../../context/Context';
import { fetchForm, editForm } from '../../context/forms/FormsAction';
import { useDropzone } from 'react-dropzone'
import { useParams } from 'react-router-dom';
import { URL, makeHttpRequest } from "../../service/Service";
import Breadcrumb from '../../components/Breadcrumb';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Header from "../../components/TitleAndSubtitle";


// const options = ["", "Cartão de Crédito", "Multibanco", "Mb Way", "Edenred", "Paysafe Card", "Paysafe Cash", "Débitos Diretos", "Payshop", "Przelewy24", "Cofidis Pay", "Santander Consumer", "Bizum"];

const options = ["", "Cartão de Crédito", "Multibanco", "Mb Way"];


const paymentImages = {
  "Cartão de Crédito": "https://eupago.pt/images/tarifario-meios-12.webp",
  "Multibanco": "https://eupago.pt/images/tarifario-meios-08.webp",
  "Mb Way": "https://eupago.pt/images/tarifario-meios-01.webp",
  // "Edenred": "https://eupago.pt/images/tarifario-meios-02.webp",
  // "Paysafe Card": "https://eupago.pt/images/tarifario-meios-03.webp",
  // "Paysafe Cash": "https://eupago.pt/images/tarifario-meios-04.webp",
  // "Débitos Diretos": "https://eupago.pt/images/tarifario-meios-05.webp",
  // "Payshop": "https://eupago.pt/images/tarifario-meios-06.webp",
  // "Przelewy24": "https://eupago.pt/images/tarifario-meios-07.webp",
  // "Cofidis Pay": "https://eupago.pt/images/tarifario-meios-11.webp",
  // "Santander Consumer": "https://eupago.pt/images/tarifario-meios-13.webp",
  // "Bizum": "https://eupago.pt/images/tarifario-meios-14.webp",
}

const FormSettings = () => {
  const { state, dispatch } = useContext(Context);
  const { form } = state;
  const { idform } = useParams();
  const [fileName, setFileName] = useState("");
  const [showCancelButton, setShowCancelButton] = useState(false);
  const [URI, setURI] = useState();
  const [file, setFile] = useState();
  const [name, setName] = useState(form.data.name);
  const [info, setInfo] = useState(form.data.info)
  const [latitude, setLatitude] = useState(form.data.latitude);
  const [longitude, setLongitude] = useState(form.data.longitude);
  const [apiKey, setApiKey] = useState(form.data.apiKey);
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState();


  const pages = [
    {
      name: 'Editor Formulário',
      href: `/forms/${idform}/formeditor`,
      current: false
    },
    {
      name: 'Definições Formulário',
      href: `/forms/${idform}/formeditor`,
      current: true
    }
  ];

  useEffect(() => {
    const url = `${URL}/api/forms/${idform}`;
    fetchForm(url, {}, dispatch);
  }, [dispatch, idform]);

  useEffect(() => {
    setPaymentOptions(form.data?.paymentOptions ?? []);
  }, [form.data?.paymentOptions]);

  const uploadFileToForm = useCallback((file) => {
    const formData = new FormData();
    formData.append("file", file);
    const url = `${URL}/uploadFile/${form.data.id}`;
    const request = {
      method: "POST",
      body: formData,
    };

    makeHttpRequest(
      url,
      request,
      (response) => {
        console.log(response);
        setURI(response.fileDownloadUri);
        window.location.reload(); // Add this statement
      },
      (error) => {
        console.error(error);
      }
    );
  }, [form.data.id]);

  const updateHandler = useCallback(() => {
    const url = `${URL}/api/forms/${idform}`;
    const request = {
      method: "PATCH",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        name: name,
        paymentOptions: paymentOptions,
        apiKey: apiKey,
        info: info,
        latitude: latitude,
        longitude: longitude,
      }),
    };
    editForm(url, request, dispatch);
    window.location.reload();
  }, [idform, name, paymentOptions, apiKey, info, latitude, longitude, dispatch]);

  const removeLogo = useCallback(() => {
    const url = `${URL}/api/forms/${idform}`;
    const request = {
      method: "PATCH",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        logo: "removed",
      }),
    };
    editForm(url, request, dispatch);
    window.location.reload();
  }, [dispatch, idform]);

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      updateHandler();
    },
    [updateHandler]
  );

  const onDrop = useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0]);
    setFileName(acceptedFiles[0].name);
    setShowCancelButton(true);
  }, []);

  const handleCancel = useCallback(() => {
    setFile(null);
    setFileName("");
    setShowCancelButton(false);
  }, []);

  const handleNameChange = useCallback((event) => {
    setName(event.target.value);
  }, []);

  const handleInfoChange = useCallback((event) => {
    setInfo(event.target.value);
  }, []);

  const handleApiKeyChange = useCallback((event) => {
    setApiKey(event.target.value);
  }, []);

  const handleLatitudeChange = useCallback((event) => {
    setLatitude(event.target.value);
  }, []);

  const handleLongitudeChange = useCallback((event) => {
    setLongitude(event.target.value);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/*",
  });

  const handleOptionChange = useCallback((event) => {
    setSelectedOption(event.target.value);
  }, []);

  const handleAddOption = () => {
    if (selectedOption && !paymentOptions.includes(selectedOption)) {
      setPaymentOptions([...paymentOptions, selectedOption]);
      setSelectedOption("");
    }
  };

  const handleDeleteOption = (optionToDelete) => {
    const newOptions = paymentOptions.filter((option) => option !== optionToDelete);
    setPaymentOptions(newOptions);
  }


  return (
    <div className="p-5">
      <Header title='Definições' />
      <Breadcrumb pages={pages} />
      <div className='mb-10'></div>
      <div className='' style={{ overflowY: 'scroll' }}>
        <form className="mt-2 bg-white p-6 rounded border shadow-md" onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="default-input" className="block font-bold text-gray-900">Nome:</label>
            <input
              className="w-full border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block p-2.5"
              type="text"
              defaultValue={form.data.name}
              onChange={handleNameChange}
            />
          </div>
          <div className='mb-8'></div>
          <div className="mb-4">
            <label htmlFor="default-input" className="block font-bold text-gray-900">Chave API:</label>
            <input
              className="w-full border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block p-2.5"
              type="text"
              defaultValue={form.data.apiKey}
              onChange={handleApiKeyChange}
            />
          </div>
          <div className='mb-8'></div>

          <div>
            <label htmlFor="default-input" className="block font-bold text-gray-900">Informação do Formulário:</label>
            <textarea
              className="max-h-64 h-20 w-full border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block p-2.5"
              type="text"
              defaultValue={form.data.info}
              onChange={handleInfoChange}
            />
            <small>Aceita tags HTML como <b>{'<b>'}</b> texto exemplo<b>{'</b>'}</b> para negrito e <b>{'<p>'}</b> texto exemplo<b>{'</p>'}</b> para parágrafo.</small>
            <div className='mb-8'></div>
          </div>
          <div className="flex flex-col md:flex-row ">
            <div className="w-full md:w-1/2 md:mr-2">
              <label htmlFor="default-input" className="block font-bold text-gray-900">Latitude:</label>
              <input
                className="w-full border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                type="text"
                defaultValue={form.data.latitude}
                onChange={handleLatitudeChange}
              />
            </div>
            <div className="w-full md:w-1/2 md:ml-2 mt-4 md:mt-0">
              <label htmlFor="default-input" className="block font-bold text-gray-900">Longitude:</label>
              <input
                className="w-full border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                type="text"
                defaultValue={form.data.longitude}
                onChange={handleLongitudeChange}
              />
            </div>
          </div>
          <small className='mb-4'>Adicione as coordenadas do seu Evento para visualizar o mapa do local, mantenha os valores a 0 para não mostrar o mapa.</small>
          <div className='mb-8'></div>
          <div>
            <div className="mb-4">
              <label htmlFor="default-input" className="block font-bold text-gray-900">
                Adicionar Métodos de Pagamento:
              </label>
              <div className='flex'>
                <select
                  value={selectedOption}
                  onChange={handleOptionChange}
                  className="mr-2 w-full bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                >
                  {options.map((option) => (
                    <option key={option} value={option}>
                      {option || "Escolha um Método"}
                    </option>
                  ))}
                </select>
                <button type="button" className="w-full sm:w-auto mt-4 sm:mt-0 bg-green-cl hover:bg-opacity-80 text-white h-10 px-4 rounded" onClick={handleAddOption}>+</button>
              </div>
            </div>
          </div>
          <div className='mb-8'></div>
          <label htmlFor="default-input" className="block font-bold text-gray-900 mb-2">Métodos Ativos:</label>
          <div className='mb-5'></div>
          <div className="flex flex-wrap">
            {paymentOptions.map((option) => (
              <div
                key={option}
                className="flex items-center mb-8 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 px-2 relative"
              >
                <div className="bg-white p-6 rounded border items-center justify-center text-center shadow-md overflow-hidden w-full hover:shadow-lg transition duration-300 ease-in-out">
                  <div className="flex justify-center">
                    <img
                      src={paymentImages[option]}
                      alt={option}
                      className="h-10 mb-4"
                    />
                  </div>
                  <div className="mb-4 font-bold">{option}</div>
                  <button
                    type="button"
                    onClick={() => handleDeleteOption(option)}
                    className="absolute top-0 left-0 bottom-0 shadow bg-red-500 hover:bg-opacity-80 text-white w-6 rounded"
                  >
                    <DeleteForeverIcon />
                  </button>
                </div>
              </div>
            ))}
          </div>
          <div className="flex flex-col sm:flex-row items-center justify-center sm:justify-end gap-2">
            <button className="w-full sm:w-auto mt-4 sm:mt-0 bg-green-cl hover:bg-opacity-80 text-white py-2 px-4 rounded" type="submit">
              Guardar
            </button>
          </div>
          <div className='mb-8'></div>
          <div className="mt-8">
            {form.data.logo !== "" && form.data.logo !== "removed" && (
              <div className="relative mb-4">
                <div className="h-64 bg-white rounded border shadow flex items-center justify-center p-6">
                  <img className="max-w-full max-h-full" src={form.data.logo} alt='' />
                  <button
                    type="button"
                    onClick={() => removeLogo()}
                    className="absolute top-0 left-0 bottom-0 shadow bg-red-500 hover:bg-opacity-80 text-white w-6 rounded"
                  >
                    <DeleteForeverIcon />
                  </button>
                </div>
              </div>
            )}
            <div {...getRootProps()} className="cursor-pointer mb-4 p-10 border border-dashed items-center justify-center text-center bg-gray-200 border-gray-400 rounded w-full">
              <center>
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p className="text-gray-700 w-fit font-medium">Largue aqui a sua imagem</p>
                ) : (
                  fileName ? null : <p className="items-center text-center justify-center text-gray-700 w-fit font-medium">
                    Arraste uma imagem para aqui
                  </p>
                )}
                {fileName ? <p className="mt-2 items-center w-fit text-gray-700 font-medium">Ficheiro: {fileName}</p> : null}
              </center>
            </div>
            <div className="flex flex-col sm:flex-row items-center justify-center sm:justify-end gap-2">
              <button type="button" onClick={() => uploadFileToForm(file)} className="w-full sm:w-auto mt-2 sm:mt-0 bg-green-cl hover:bg-opacity-80 text-white py-2 px-4 rounded">
                Enviar
              </button>
              {showCancelButton ? (
                <button type="button" onClick={handleCancel} className="w-full sm:w-auto mt-2 sm:mt-0 bg-green-cl hover:bg-opacity-80 text-white py-2 px-4 rounded">
                  Cancelar
                </button>
              ) : null}
            </div>
          </div>
        </form >
      </div >
    </div>

  );
};

export default FormSettings