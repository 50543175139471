import React from 'react';
import Checkbox from './Checkbox';
import Select from './Select';
import Input from './Input';
import Datepicker from './Datepicker';
import MultilineInput from './MultilineInput';
import ChooseOption from './ChooseOption';
import TextBlock from './TextBlock';
import StarRating from './StarRating';
import Divider from './Divider';
import Title from './Title';

const Element = ({ formElement: { element_option, element_type, element_value, id, label, links, photo, placeholder, selection, cost, required, regex }, onInputChange  }) => {
    switch (element_type) {
        case 'input':
            return (
                <>
                    <Input
                        placeholder={placeholder}
                        label={label}
                        required={required}
                        onInputChange={onInputChange}
                        regex={regex}
                    />

                </>
            )
        case 'multiline':
            return (
                <>
                    <MultilineInput
                        label={label}
                        required={required}
                        onInputChange={onInputChange}
                    />
                </>)
        case 'select':
            return (
                <>
                    <Select
                        label={label}
                        placeholder={placeholder}
                        element_option={element_option}
                        required={required}
                        onInputChange={onInputChange}
                    />

                </>)
        case 'choose':
            return (
                <>
                    <ChooseOption
                        id={id}
                        label={label}
                        element_option={element_option}
                        onInputChange={onInputChange}
                        required={required}
                    />
                </>)
        case 'checkbox':
            return (
                <>
                    <Checkbox
                        label={label}
                        required={required}
                        placeholder={placeholder}
                        onInputChange={onInputChange}

                    />

                </>)
        case 'datepicker':
            return (
                <>
                    <Datepicker
                        label={label}
                        required={required}
                        placeholder={placeholder}
                        onInputChange={onInputChange}

                    />

                </>)
        case 'textblock':
            return (
                <>
                    <TextBlock
                        id={id}
                        label={label}
                        placeholder={placeholder}
                    />
                </>
            )
        case 'rating':
            return (
                <>
                    <StarRating
                        id={id}
                        label={label}
                        required={required}
                        onInputChange={onInputChange}
                    />
                </>)
        case 'title':
            return (
                <>
                    <Title
                        id={id}
                        label={label}
                        placeholder={placeholder}
                        required={required}
                    />
                </>)
        case 'divider':
            return (
                <>
                    <Divider />
                </>)
        default:
            return null;
    }
}

export default Element