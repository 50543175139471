import React from 'react'
import InputEditWizard from './InputEditWizard'
import CheckboxEditWizard from './CheckboxEditWizard'
import SelectEditWizard from './SelectEditWizard'
import MultilineEditWizard from './MultilineEditWizard'
import DatepickerEditWizard from './DatepickerEditWizard'
import TextblockEditWizard from './TextblockEditWizard'
import ChooseOptionEditWizard from './ChooseOptionEditWizard'
import RatingEditWizard from './RatingEditWizard'
import TitleEditWizard from './TitleEditWizard'
import PriceEditWizard from './PriceEditWizard'


const ElementEditWizard = ({ formElement: { element_option, element_type, element_value, id, label, links, photo, placeholder, selection, required, regex }, userRole}) => {

    switch (element_type) {
        case 'input':
            return (
                <InputEditWizard
                    elementInfo={{ label, placeholder, required, regex }}
                    userRole = {userRole}
                />
            )
        case 'multiline':
            return (
                <MultilineEditWizard
                    elementInfo={{ label, placeholder, required }}
                    userRole = {userRole}

                />
            )
        case 'select':
            return (
                <SelectEditWizard
                    elementInfo={{ label, placeholder, required, element_option }}
                    userRole = {userRole}

                />
            )
        case 'choose':
            return (
                <ChooseOptionEditWizard
                    elementInfo={{ label, placeholder, required, element_option }}
                    userRole = {userRole}

                />
            )
        case 'checkbox':
            return (
                <CheckboxEditWizard
                    elementInfo={{ label, placeholder, required }}
                    userRole = {userRole}

                />
            )
        case 'datepicker':
            return (
                <DatepickerEditWizard
                    elementInfo={{ label, required }}
                    userRole = {userRole}

                />
            )
        case 'textblock':
            return (
                <TextblockEditWizard
                    elementInfo={{ label, placeholder }}
                    userRole = {userRole}

                />
            )
        case 'rating':
            return (
                <RatingEditWizard
                    elementInfo={{ label, required }}
                    userRole = {userRole}

                />
            )
        case 'title':
            return (
                <TitleEditWizard
                    elementInfo={{ label, placeholder }}
                    userRole = {userRole}

                />
            )
        case 'price':
            return (
                <PriceEditWizard
                    elementInfo={{ label, element_value }}
                    userRole = {userRole}

                />
            )
        // case 'multi_input':
        //     return (
        //         <>multi_input</>
        //     )
        // case 'multi_input2':
        //     return (
        //         <>multi_input2</>
        //     )
        default:
            return null;
    }
}

export default ElementEditWizard