import React, { useContext, useEffect, useState, useReducer } from 'react';
import Context from '../../context/Context';
import { editFormElement, fetchFormElement } from '../../context/forms/FormsAction';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { URL } from "../../service/Service";
import ElementEdit from "../../components/elementEdit/ElementEdit"
import ElementEditWizard from '../../components/wizardElementEdit/ElementEditWizard';
import { jwtDecode } from 'jwt-decode';

const ElementEditorWizard = () => {
  const { idform, idformelement } = useParams()
  const [element, setElement] = useState();
  const { state, dispatch } = useContext(Context);
  const { loading, error, data } = state.formelement;
  const token = sessionStorage.getItem('jwt');
  const userRole = jwtDecode(token).authorities[0].authority;
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const url = `${URL}/api/forms/${idform}/formelements/${idformelement}`;
    fetchFormElement(url, {}, dispatch);
    setElement(element)
  }, []);

  useEffect(() => {
    const isAdminPath = location.pathname.startsWith('/admin/forms/');
    const isFormWizard1Path = location.pathname.endsWith('/wizard');

    if (isAdminPath && isFormWizard1Path && userRole !== 'ADMIN') {
      navigate('/login'); // Redirect to login screen if not ADMIN and accessing admin path
    }
  }, [location.pathname, navigate, userRole]);


  return (
    <>
      <ElementEditWizard formElement={data} userRole={userRole} />
    </>
  )
}

export default ElementEditorWizard