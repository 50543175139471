import React, { useContext } from 'react'


const Divider = () => {

    return (
        <>
            <div className="m-2 mr-5 w-full bg-white rounded shadow border p-4">
                <hr className="h-px my-8 bg-black border-0 dark:bg-gray-700"/>
            </div>
        </>
    )
}

export default Divider