import React, { useContext, useEffect, useState, useRef } from 'react';
import Context from '../../context/Context';
import { fetchFormElements, fetchForm } from '../../context/forms/FormsAction';
import { useParams } from 'react-router-dom';
import { URL } from "../../service/Service";
import Element from "../../components/clientFormElements/Element"
import PriceElement from "../../components/clientFormElements/PriceElement"
import Spinner from '../../components/Spinner';
import euforms from "../../images/euforms.png"
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import HTMLRenderer from '../../components/HTMLRenderer';
import Header from '../../components/TitleAndSubtitle';
import { CreditCardIcon, ShoppingBagIcon, DocumentTextIcon } from '@heroicons/react/24/outline'
import CheckoutArea from '../../components/CheckoutArea';

const FormReader = () => {
    const { idform } = useParams()
    const { state, dispatch } = useContext(Context);
    const { loading, data } = state.formelements;
    const page_label = state.form.data.name;
    const page_logo = state.form.data.logo;
    const info = state.form.data.info;

    const api = state.form.data.apiKey;
    const paymentOptions = state.form.data.paymentOptions;
    const latitude = state.form.data.latitude;
    const longitude = state.form.data.longitude;
    const [formElements, setFormElements] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [selectedOption, setSelectedOption] = useState('');
    const [formElementsWithNumber, setFormElementsWithNumber] = useState([]);
    const [areRequiredFieldsFilled, setAreRequiredFieldsFilled] = useState(false);
    const mapRef = useRef(null);
    const [fixedValue, setFixedValue] = useState(0);
    const [itemPurchases, setItemPurchases] = useState({});
    const [regexMessages, setRegexMessages] = useState([]);
    const [answers, setAnswers] = useState({});


    const options = [];
    for (let i = 0; i <= 10; i++) {
        options.push(<option key={i} value={i}>{i}</option>);
    }

    // const paymentImages = {
    //     "Cartão de Crédito": "https://eupago.pt/images/tarifario-meios-12.webp",
    //     "Multibanco": "https://eupago.pt/images/tarifario-meios-08.webp",
    //     "Mb Way": "https://eupago.pt/images/tarifario-meios-01.webp",
    //     "Edenred": "https://eupago.pt/images/tarifario-meios-02.webp",
    //     "Paysafe Card": "https://eupago.pt/images/tarifario-meios-03.webp",
    //     "Paysafe Cash": "https://eupago.pt/images/tarifario-meios-04.webp",
    //     "Débitos Diretos": "https://eupago.pt/images/tarifario-meios-05.webp",
    //     "Payshop": "https://eupago.pt/images/tarifario-meios-06.webp",
    //     "Przelewy24": "https://eupago.pt/images/tarifario-meios-07.webp",
    //     "Cofidis Pay": "https://eupago.pt/images/tarifario-meios-11.webp",
    //     "Santander Consumer": "https://eupago.pt/images/tarifario-meios-13.webp",
    //     "Bizum": "https://eupago.pt/images/tarifario-meios-14.webp",
    // }

    function getRegexString(regexType) {
        switch (regexType) {
            case "NUMBER":
                return /^\d+$/;
            case "TEXT":
                return /^[a-zA-Z0-9\s.,'"\-!@#$%^&*()_+=<>?/]*$/;
            case "NIF":
                return /^[a-zA-Z0-9]{1,20}$/;
            case "NIFPT":
                return /^\d{9}$/;
            case "EMAIL":
                return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            case "PHONE":
                return /^\+?[1-9]\d{1,14}$/;
            case "URL":
                return /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/;
            case "EMPTY":
                return /.*/;
            default:
                return /.*/;
        }
    }


    const handleInputChange = (label, value) => {
        setAnswers((prevState) => ({ ...prevState, [label]: value }));

        setFormElements((prevState) => {
            const updatedFormElements = prevState.map((element) => {
                if (element.label === label) {
                    if (element.element_type === 'price') {
                        return { ...element, number: value };
                    } else {
                        return { ...element, element_value: value };
                    }
                }
                return element;
            });

            const areAllRequiredFieldsFilled = updatedFormElements.every(
                (element) => !element.required || element.element_value !== ""
            );

            setAreRequiredFieldsFilled(areAllRequiredFieldsFilled);

            const element = updatedFormElements.find(el => el.label === label);
            if (element && (element.element_type === 'input')) {
                const regexPattern = getRegexString(element.regex);

                // If the input doesn't match the regex and it's not empty
                if (!regexPattern.test(value) && value !== "") {
                    setRegexMessages((prevMessages) => {
                        const message = `Preencha o campo ${element.label} corretamente`;
                        // Avoid duplicate messages
                        return prevMessages.includes(message) ? prevMessages : [...prevMessages, message];
                    });
                } else {
                    // If the input is valid, remove any existing error message for this element
                    setRegexMessages((prevMessages) => prevMessages.filter(msg => msg !== `Preencha o campo ${element.label} corretamente`));
                }
            }

            return updatedFormElements;
        });
    };

    console.log(regexMessages);

    const DefaultIcon = L.icon({
        iconUrl: icon,
        shadowUrl: iconShadow
    });
    L.Marker.prototype.options.icon = DefaultIcon;

    useEffect(() => {
        const updatedFormElements = formElements.map(element => {
            const newElement = { ...element };
            if (newElement.value === 0) {
                newElement.number = 0;
            }
            return newElement;
        });
        setFormElementsWithNumber(updatedFormElements);
    }, [formElements]);

    useEffect(() => {
        const url = `${URL}/api/forms/${idform}/formelements`;
        const url_form = `${URL}/api/forms/${idform}`;
        fetchFormElements(url, {}, dispatch);
        fetchForm(url_form, {}, dispatch);
    }, [dispatch, idform]);

    useEffect(() => {
        if (data) {
            setFormElements(Object.values(data));
        }
    }, [data]);

    useEffect(() => {
        let totalValue = 0;
        const purchasedItems = {};

        // Ensure formElements is not undefined before iterating over it
        if (formElements) {
            for (const formElement of formElements) {
                if (formElement.element_type === 'price') {
                    const price = parseFloat(formElement.element_value) || 0;
                    const quantity = parseInt(formElement.number) || 0;
                    totalValue += price * quantity;
                    purchasedItems[formElement.id] = quantity;
                }
            }
        }

        setFixedValue(totalValue);
        setItemPurchases(purchasedItems);
    }, [formElements]);



    useEffect(() => {
        if (mapRef.current !== null) {
            const map = L.map(mapRef.current).setView([latitude, longitude], 13);

            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors',
                maxZoom: 18,
            }).addTo(map);

            L.marker([latitude, longitude]).addTo(map);

            return () => {
                map.remove();
            };
        }
    }, [latitude, longitude, mapRef.current]);

    useEffect(() => {
        let sum = 0;
        for (const element of formElementsWithNumber) {
            const price = parseFloat(element.element_value) || 0;
            const count = parseFloat(element.number) || 0;
            sum += price * count;
        }
        setTotalPrice(sum);
    }, [formElementsWithNumber]);

    const replyFields = [];

    for (const key in answers) {
        if (answers.hasOwnProperty(key)) {
            replyFields.push({
                label: key.toUpperCase(),
                answer: answers[key].toString()
            });
        }
    }

    const formReplies = {
        formID: idform,
        replyFields: replyFields
    };

    const handleMethodSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleSelectChange = (index, value) => {
        setFormElements(prevState => {
            const newFormElements = [...prevState];
            if (value === '0') {
                newFormElements[index] = { ...newFormElements[index], number: 0 };
            } else {
                newFormElements[index] = { ...newFormElements[index], number: parseInt(value) };
            }
            return newFormElements;
        });
    };

    if (loading === true) {
        return (
            <Spinner></Spinner>
        )
    }

    return (
        <div className='overflow-x-hidden'>
            <nav className="flex items-center justify-between flex-wrap bg-green-cl p-6">
                <div className="flex items-center flex-shrink-0 text-white mr-6">
                    <img className="h-8" src={euforms} alt="Logo" />
                </div>
            </nav>
            <div className="p-10 w-screen sm:py-12">
                <div className="relative px-4 py-10 bg-white sm:mx-8 md:mx-0 shadow rounded border sm:p-10">
                    <div className="mx-auto">
                        <div className='flex w-full'>
                            <DocumentTextIcon className='mr-6 w-10 h-10'></DocumentTextIcon>
                            <Header title={page_label} />
                        </div>
                        <hr className="border-b my-4 mb-10" />
                        <div className="flex items-left space-x-5"></div>
                        <form>
                            {formElements && formElements.map((formElement, i) => (
                                <div key={formElement.id}>
                                    <Element i={i} formElement={formElement} formId={idform} onInputChange={handleInputChange} />
                                </div>
                            ))}

                            <hr className="h-px my-8 bg-black border-0 dark:bg-gray-700" />
                            <div className='flex w-full'>
                                <Header title="Carrinho" />
                                <ShoppingBagIcon className='ml-6 w-10 h-10'></ShoppingBagIcon>
                            </div>
                            {formElements && formElements.map((formElement, i) => (
                                <div key={formElement.id} className='flex flex-col md:flex-row justify-between items-center md:items-start'>
                                    <PriceElement i={i} formElement={formElement} formId={idform} />
                                    {formElement.element_type === 'price' && (
                                        <div className="bg-white h-16 p-2 mb-5 rounded-md shadow-md border justify-between w-full md:w-fit flex items-center">
                                            <span className="text-gray-500 mr-2 font-medium text-sm uppercase tracking-wider">Quantidade</span>
                                            <input
                                                type="number"
                                                onChange={e => {
                                                    handleSelectChange(i, e.target.value);
                                                }}
                                                className='text-xl border-transparent font-bold focus:border-transparent  focus:ring-transparent w-20 md:w-24'
                                                placeholder="0"
                                                step="1"
                                                min="0"
                                            />
                                        </div>
                                    )}
                                </div>
                            ))}

                            <div className="bg-gray-100 p-4 rounded-lg">
                                <h2 className="text-lg font-medium">Preço Total:</h2>
                                <p className="text-2xl font-bold">{totalPrice.toFixed(2)} €</p>
                            </div>
                            {
                                (totalPrice > 0 && !areRequiredFieldsFilled) && (
                                    <div className="fixed bottom-0 right-0 mb-4 mr-4 ">
                                        <div className="mt-10 relative flex flex-col animate-fade-up animate-delay-500 animate-ease-in sm:flex-row sm:items-center border border-gray-500 bg-red-200 shadow rounded-md py-5 pl-6 pr-8 sm:pr-6 animate-slide-up">
                                            <div className="flex flex-row items-center border-b sm:border-b-0 w-full sm:w-auto pb-4 sm:pb-0">
                                                <div className="text-orange-500">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                                                    </svg>
                                                </div>
                                                <div className="text-sm text-gray-600 ml-4">Preencha os campos obrigatórios</div>
                                            </div>
                                        </div>
                                    </div>)
                            }
                            {
                                (totalPrice > 0 && regexMessages.length > 0) && (
                                    <div className="fixed bottom-0 right-0 mb-4 mr-4">
                                        <div className="mt-10 relative flex flex-col animate-fade-up animate-delay-500 animate-ease-in sm:flex-row sm:items-center border border-gray-500 bg-red-200 shadow rounded-md py-5 pl-6 pr-8 sm:pr-6 animate-slide-up">
                                            <div className="flex flex-row items-center border-b sm:border-b-0 w-full sm:w-auto pb-4 sm:pb-0">
                                                <div className="text-orange-500">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                                                    </svg>
                                                </div>
                                                <div className="text-sm text-gray-600 ml-4">
                                                    {regexMessages.map((message, index) => (
                                                        <p key={index}>{message}</p>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            {totalPrice > 0 && areRequiredFieldsFilled && regexMessages.length === 0 && (
                                <div className="mt-10">
                                    <hr className="h-px my-8 bg-black border-0 dark:bg-gray-700" />
                                    <div className='flex w-full'>
                                        <Header title="Pagamento" />
                                        <CreditCardIcon className='ml-6 w-10 h-10'></CreditCardIcon>
                                    </div>
                                    <label htmlFor="default-input" className="block font-bold text-gray-900">Escolha o seu método de pagamento:</label>
                                    <div className="flex items-center space-x-4">
                                        <select
                                            value={selectedOption}
                                            onChange={handleMethodSelectChange}
                                            className="w-full bg-white border border-gray-300 text-gray-900 text-sm rounded block p-2.5"
                                        >
                                            <option selected >
                                                Escolha um Método
                                            </option>
                                            {paymentOptions.map((option) => (
                                                <option key={option} value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <CheckoutArea method={selectedOption} value={totalPrice} api={api} formReplies={formReplies} fixedValue={fixedValue} itemPurchases={itemPurchases}></CheckoutArea>
                                </div>
                            )}

                        </form>
                    </div>
                </div>
                {/* </div> */}
                {/* </div> */}
            </div >
            <footer className="flex items-center text-center justify-center bg-green-cl p-4">
                <div className="flex items-center flex-shrink-0 text-white mr-6">
                    <small>© Eupago - Instituição de Pagamento - {new Date().getFullYear()}</small>
                </div>
            </footer>
        </div >
    )
}

export default FormReader;
