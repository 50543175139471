// export const URL = 'http://localhost:8080';
// export const URL_FRONT = "http://localhost:3000";

export const URL = "https://forms.eupago.pt"
export const URL_FRONT = "https://formularios.eupago.pt";

export function makeHttpRequest(url, request, success, failure) {
  let token = sessionStorage.getItem("jwt");
  let headers = new Headers();
  headers.append("Authorization", `Bearer ${token}`);

  if (request.body instanceof FormData) {
    headers.delete("Content-Type");
  } else {
    headers.append("Content-Type", "application/json");
  }

  headers.append("Accept", "application/json");
  request.headers = headers;
  fetch(url, request)
    .then(res => {
      if (res.ok) {
        return res.json()
      }
      else {
        return res.text().then(text => { throw new Error(text) })
      }
    })
    .then(res => success(res))
    .catch(err => failure(err));
}

export function makeHttpRequestWithoutToken(url, request, success, failure) {
  let headers = new Headers();

  if (request.body instanceof FormData) {
    headers.delete("Content-Type");
  } else {
    headers.append("Content-Type", "application/json");
  }

  headers.append("Accept", "application/json");
  request.headers = headers;
  fetch(url, request)
    .then(res => {
      if (res.ok) {
        return res.json()
      }
      else {
        return res.text().then(text => { throw new Error(text) })
      }
    })
    .then(res => success(res))
    .catch(err => failure(err));
}

export function makeHttpRequestNoLogin(url, request, success, failure) {
  let headers = new Headers();

  headers.append("Accept", "application/json");
  request.headers = headers;
  fetch(url, request)
    .then(res => {
      if (res.ok) {
        return res.json()
      }
      else {
        return res.text().then(text => { throw new Error(text) })
      }
    })
    .then(res => success(res))
    .catch(err => failure(err));
}




