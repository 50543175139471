import React from 'react';
import spinnerlogo from '../images/euformsmall.png'

const Spinner = () => {
  return (
    <div className="flex flex-grow h-screen justify-center items-center">

      <div role="status">
          <img src={spinnerlogo} className='animate-animation w-12' alt=''></img>
          <h2 className="text-xl font-bold text-center text-green-cl">Loading</h2>      </div>
    </div>
  );
};

export default Spinner;