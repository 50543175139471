import React from 'react'


const Title = ({ placeholder, label }) => {

    return (
        <>
            <div className="m-2 mr-5 w-full bg-white rounded shadow border p-4">
                <div className="flex flex-col items-left text-left justify-center p-3">
                    <header className="font-bold leading-tight">
                        <div className="text-3xl font-bold mb-1 text-black">{label}</div>
                        <div className="font-normal text-sm mb-3 text-black">{placeholder}</div>
                    </header>
                </div>
                </div>
            </>
            );
};

            export default Title;