import React from 'react'


const Select = ({ label, element_option}) => {


    return (
        <>
            <div className="m-2 mr-5 w-full bg-white rounded shadow border p-4">
                <div className="mb-6">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{label}</label>
                    <select id="options" className="mt-1 w-full border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:outline-none focus:border-blue-500 block p-2.5 h-fit">
                        {element_option.map((option) => (
                            <option key={option} value={option}>{option}</option>
                        ))}
                    </select>
                </div>
            </div>

        </>
    )
}

export default Select