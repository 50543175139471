import React, { useContext, useEffect, useState, useMemo, useCallback } from 'react';
import Context from '../../context/Context';
import { fetchFormElements, fetchForm, createFormElement, editFormElement } from '../../context/forms/FormsAction';
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom';
import { URL, URL_FRONT } from "../../service/Service";
import Element from "../../components/formElements/Element"
import Spinner from '../../components/Spinner';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Title, TextRotationNone, CheckBox, ArrowDropDownCircle, TextFields, DateRange, Info, FormatListBulleted, Star, HorizontalRule, AttachMoney, Settings } from '@mui/icons-material';
import { jwtDecode } from 'jwt-decode';

const FormWizard1 = () => {
    const { idform } = useParams()
    const { state, dispatch } = useContext(Context);
    const { loading, error, data } = state.formelements;
    const createElementURL = `${URL}/api/forms/${idform}/formelements`
    const [formElements, setFormElements] = useState([]);
    const [showNav, setShowNav] = useState(true);
    const [alert, setAlert] = useState(formElements.length === 0);
    const [priceAlert, setPriceAlert] = useState(!formElements.some(element => element.element_type === "price" && formElements.length > 0));
    const [draggedIndex, setDraggedIndex] = useState(null);
    const navigate = useNavigate()
    const token = sessionStorage.getItem('jwt');
    const userRole = jwtDecode(token).authorities[0].authority;
    const location = useLocation();

    useEffect(() => {
        if (formElements.length > 0) {
            const hasPriceElement = formElements.some(element => element.element_type === "price");
            setPriceAlert(!hasPriceElement);
        }
    }, [formElements]);

    useEffect(() => {
        const isAdminPath = location.pathname.startsWith('/admin/forms/');
        const isFormWizard1Path = location.pathname.endsWith('/formwizard/1');

        if (isAdminPath && isFormWizard1Path && userRole !== 'ADMIN') {
            navigate('/login'); // Redirect to login screen if not ADMIN and accessing admin path
        }
    }, [location.pathname, navigate, userRole]);


    const toggleNav = () => setShowNav(prevState => !prevState);


    const ELEMENTS = [
        { name: "Título", type: "title", icon: <Title /> },
        { name: "Caixa de Texto", type: "input", icon: <TextRotationNone /> },
        { name: "Caixa de Validação", type: "checkbox", icon: <CheckBox /> },
        { name: "Caixa de Seleção", type: "select", icon: <ArrowDropDownCircle /> },
        { name: "Caixa de Comentário", type: "multiline", icon: <TextFields /> },
        { name: "Data", type: "datepicker", icon: <DateRange /> },
        { name: "Caixa de Informação", type: "textblock", icon: <Info /> },
        { name: "Escolha Múltipla", type: "choose", icon: <FormatListBulleted /> },
        { name: "Avaliação", type: "rating", icon: <Star /> },
        { name: "Linha de Divisão", type: "divider", icon: <HorizontalRule /> },
        { name: "Campo de Preço", type: "price", icon: <AttachMoney /> },
    ];


    useEffect(() => {
        const url = `${URL}/api/forms/${idform}/formelements`;
        const url_form = `${URL}/api/forms/${idform}`;
        async function fetchData() {
            await fetchFormElements(url, {}, dispatch);
            await fetchForm(url_form, {}, dispatch);
            setFormElements(Object.values(data));

        }
        fetchData();
    }, [dispatch, idform]);

    useEffect(() => {
        setFormElements(Object.values(data));
    }, [data]);


    const handleChange = useCallback((input_id, event) => {
        setFormElements(prevFormElements =>
            prevFormElements.map(data => {
                const { element_type } = data;
                if (input_id === data.id) {
                    switch (element_type) {
                        case 'checkbox':
                            return { ...data, element_value: event.target.checked };
                        case 'datepicker':
                            return { ...data, element_value: event };
                        default:
                            return { ...data, element_value: event.target.value };
                    }
                }
                return data;
            })
        );
    }, []);

    const memoizedValue = useMemo(() => ({ handleChange }), [handleChange]);

    const updateHandler = (position, elementId) => {
        const url = `${URL}/api/forms/${idform}`;
        const request = {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({ position, elementId }),
        };
        editFormElement(url, request, dispatch);
    };

    const deleteHandler = (elementId) => {
        const url = `${URL}/api/forms/${idform}/formelements/${elementId}`;
        const request = {
            method: 'DELETE',
            headers: {
                'Content-type': 'application/json',
                'Accept': 'application/json',
            },
        };
        editFormElement(url, request, dispatch);
    };


    const createElement = (elementType) => {
        const request = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({ "type": elementType })
        }

        createFormElement(createElementURL, request, (response) => {
            // store the response in a variable
            const newElement = response.payload.data;
            setFormElements(formElements.concat(newElement))
            dispatch(data);
            console.log(newElement)

            // Redirect to the edit page of the newly created element
            if (newElement.element_type !== "divider") {
                let editUrl;
                if (userRole === "ADMIN") {
                    editUrl = `/admin/forms/${idform}/formelements/${newElement.id}/wizard`; // Assuming admin edit URL
                } else {
                    editUrl = `/forms/${idform}/formelements/${newElement.id}/wizard`; // Assuming regular user edit URL
                }
                navigate(editUrl);
            }
        });
    };

    const handleMoveUp = (index, elementId) => {
        const newIndex = index - 1;
        setFormElements(prevFormElements => {
            if (newIndex < 0) return prevFormElements; // cannot move up
            const newFormElements = [...prevFormElements];
            [newFormElements[index], newFormElements[newIndex]] = [newFormElements[newIndex], newFormElements[index]]; // swap elements
            return newFormElements;
        });
        updateHandler(newIndex, elementId)
    };

    const handleMoveDown = (index, elementId) => {
        const newIndex = index + 1;
        setFormElements((prevFormElements) => {
            if (newIndex >= prevFormElements.length) return prevFormElements; // cannot move down
            const newFormElements = [...prevFormElements];
            [newFormElements[index], newFormElements[newIndex]] = [newFormElements[newIndex], newFormElements[index]]; // swap elements
            return newFormElements;
        });
        updateHandler(newIndex, elementId)
    };

    const handleRemove = (index, elementId) => {
        setFormElements(prevFormElements => {
            const newFormElements = [...prevFormElements];
            newFormElements.splice(index, 1); // remove element at given index
            return newFormElements;
        });
        deleteHandler(elementId);
    };

    const handleDragStart = (e, index) => {
        setDraggedIndex(index);
    };

    const handleDragOver = (e, index) => {
        e.preventDefault();
        const draggedElement = formElements[draggedIndex];
        const newFormElements = [...formElements];
        newFormElements.splice(draggedIndex, 1);
        newFormElements.splice(index, 0, draggedElement);
        setFormElements(newFormElements);
        setDraggedIndex(index);
    };

    const handleDragEnd = () => {
        if (draggedIndex === null) return;
        const draggedElement = formElements[draggedIndex];
        const newFormElements = [...formElements];
        newFormElements.splice(draggedIndex, 1);
        newFormElements.splice(draggedIndex, 0, draggedElement);
        setFormElements(newFormElements);
        const newIndex = newFormElements.findIndex(element => element.id === draggedElement.id);
        updateHandler(newIndex, draggedElement.id);
        setDraggedIndex(null);
    };

    const handleButtonClick = () => {
        if (userRole === 'ADMIN') {
            navigate(`/admin/forms/${idform}/formwizard/2`);
        } else {
            navigate(`/forms/${idform}/formwizard/2`);
        }
    };



    if (loading === true) {
        return (
            <Spinner></Spinner>
        )
    }


    return (
        <div className='p-5'>
            <div>
                <div class="flex justify-between items-center">

                    <div className='w-full mr-10 mt-8'>
                        <div class="overflow-hidden rounded-full bg-gray-200">
                            <div class="h-2 w-0 rounded-full bg-green-cl"></div>
                        </div>

                        <ol class="mt-4 grid grid-cols-3 text-sm font-medium text-gray-500">
                            <li class="flex items-center justify-start text-black">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-4">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                </svg>

                                <span class="hidden sm:inline font-bold"> Editor </span>

                            </li>

                            <li class="flex items-center justify-center text-black">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-4">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z" />
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                </svg>
                                <span class="hidden sm:inline"> Definições </span>


                            </li>

                            <li class="flex items-center justify-end text-black">

                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-4">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5" />
                                </svg>

                                <span class="hidden sm:inline"> Upload Imagem </span>


                            </li>
                        </ol>
                    </div>
                    <button
                        className={`mt-5 md:mt-0 bg-green-cl hover:bg-opacity-80 text-white text-xs py-2 px-4 rounded ${formElements.length === 0 || (priceAlert && formElements.length > 0) ? "opacity-50 cursor-not-allowed" : ""
                            }`}
                        onClick={handleButtonClick}
                        disabled={formElements.length === 0 || formElements.length === 0 || (priceAlert && formElements.length > 0)}
                    >
                        Seguinte
                    </button>
                </div>
            </div>

            <div className='flex mt-10 w-full relative'>
                <div className={`flex rounded mr-1 border flex-col h-full ${showNav ? 'w-72' : 'w-16'} bg-white`}>
                    {showNav ? (
                        <>
                            <button className="w-full px-4 py-3 font-semibold text-gray-600 border-b border-gray-100 hover:text-gray-800 focus:outline-none" onClick={toggleNav}>
                                <div className='flex justify-between items-center'>
                                    <span className="text-lg font-bold">Elementos</span>
                                    <span className="text-gray-500 hover:text-gray-800">
                                        <KeyboardArrowLeftIcon />
                                    </span>
                                </div>
                            </button>
                            <nav className="flex-grow p-4">
                                <ul className="flex flex-col">
                                    {ELEMENTS.map((element) => (
                                        <li
                                            key={element.type}
                                            className="py-2 px-3 mb-3 rounded-lg hover:cursor-pointer hover:bg-gray-100 transition duration-200 ease-in-out"
                                            onClick={() => createElement(element.type)}
                                        >
                                            {element.icon}
                                            <span className="text-gray-700 text-sm ml-2">{element.name}</span>
                                        </li>

                                    ))}
                                </ul>
                            </nav>
                        </>
                    ) : (
                        <button className="" onClick={toggleNav}>
                            <small className='font-bold'>Elementos</small>
                            <span className=""><KeyboardArrowDownIcon /></span>
                        </button>
                    )}
                </div>
                <Context.Provider value={memoizedValue}>
                    <div className="w-full bg-white flex border rounded p-2.5">
                        <form className="w-full">
                            <div className=''>
                                {formElements ? formElements.map((formElement, i) => {
                                    return (
                                        <div
                                            key={i}
                                            className='flex'
                                            style={{ position: 'relative' }}
                                            draggable
                                            onDragStart={(e) => handleDragStart(e, i)}
                                            onDragOver={(e) => handleDragOver(e, i)}
                                            onDragEnd={handleDragEnd}
                                        >
                                            <button
                                                className="text-gray-700"
                                                type='button'
                                                onClick={() => handleRemove(i, formElement.id)}
                                                style={{ position: 'absolute', top: -4, left: -3 }}
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 24 24" stroke-width="1.5" stroke="red" className="w-6 h-6">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                </svg>
                                            </button>
                                            <Element i={i} formElement={formElement} formId={idform} />
                                            <div className='flex flex-col ml-10'>
                                                <button className="text-gray-700" type='button' onClick={() => handleMoveUp(i, formElement.id)} style={{ position: 'absolute', top: 10, right: 17 }}><ArrowUpwardIcon></ArrowUpwardIcon></button>
                                                <button className="text-gray-700" type='button' onClick={() => handleMoveDown(i, formElement.id)} style={{ position: 'absolute', bottom: 10, right: 17 }}><ArrowDownwardIcon></ArrowDownwardIcon></button>
                                                <Link to={`/forms/${idform}/formelements/${formElement.id}/wizard`}>
                                                    <button className="text-gray-700" style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: 17 }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                                        </svg>
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                    )
                                }) : null}
                            </div>
                        </form>
                    </div>
                </Context.Provider>
                {formElements.length === 0 && alert && (
                    <div className="fixed bottom-0 right-0 mb-4 mr-4 ">
                        <div className="mt-10 relative flex flex-col animate-fade-up animate-delay-500 animate-ease-in sm:flex-row sm:items-center border border-gray-500 bg-red-200 shadow rounded-md py-5 pl-6 pr-8 sm:pr-6 animate-slide-up">
                            <button className="absolute top-0 right-0 px-2 py-1" type='button' onClick={() => setAlert(false)}>x</button>
                            <div className="flex flex-row items-center border-b sm:border-b-0 w-full sm:w-auto pb-4 sm:pb-0">
                                <div className="text-orange-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                                    </svg>
                                </div>
                                <div className="text-sm text-gray-600 ml-4">Adicione um elemento para prosseguir.</div>
                            </div>
                        </div>
                    </div>
                )}
                {priceAlert && formElements.length > 0 && (
                    <div className="fixed bottom-0 right-0 mb-4 mr-4 ">
                        <div className="mt-10 relative flex flex-col animate-fade-up animate-delay-500 animate-ease-in sm:flex-row sm:items-center border border-gray-500 bg-red-200 shadow rounded-md py-5 pl-6 pr-8 sm:pr-6 animate-slide-up">
                            <button className="absolute top-0 right-0 px-2 py-1" type='button' onClick={() => setAlert(false)}>x</button>
                            <div className="flex flex-row items-center border-b sm:border-b-0 w-full sm:w-auto pb-4 sm:pb-0">
                                <div className="text-orange-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                                    </svg>
                                </div>
                                <div className="text-sm text-gray-600 ml-4">Insira um componente do tipo Campo de Preço</div>
                            </div>
                        </div>
                    </div>
                )}

            </div>
        </div>
    );
}


export default FormWizard1
