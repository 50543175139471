import React, { useState } from 'react'
import { URL } from '../../service/Service';

const MBWay = ({ value, api, formReplies, fixedValue, itemPurchases }) => {

  const [alias, setAlias] = useState('');
  const [error, setError] = useState('');
  const [isChecked, setIsChecked] = useState(false); // add state variable for checkbox
  const [isSubmittedSuccess, setIsSubmittedSuccess] = useState(false); // add state variable for form submission
  const [isSubmittedError, setIsSubmittedError] = useState(false); // add state variable for form submission
  const [email, setEmail] = useState('');
  const [replyResult, setReplyResult] = useState('');

  const url = `${URL}/api/replies`;

  const replyRequest = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    body: JSON.stringify(formReplies),
  }

  const createNewReply = async () => {
    const response = await fetch(url, replyRequest);
    const replyData = await response.text();
    const newReplyResult = JSON.parse(replyData);
    setReplyResult(newReplyResult);
    return newReplyResult;
  };

  const handleSubmit = async event => {
    event.preventDefault();
    const newReplyResult = await createNewReply();
    await handleFetch();
  };

  const handleFetch = async () => {
    try {
      const body = {
        "value": value,
        "api": api,
        "alias": alias,
        "email": email,
        "fixedValue": fixedValue,
        "itemPurchases": itemPurchases,
        "formID": formReplies.formID,
        "replyFields": formReplies.replyFields,
      };
      const options = {
        method: 'POST',
        headers: { accept: 'application/json', 'content-type': 'application/json' },
        body: JSON.stringify(body),
      };
      const response = await fetch(`${URL}/api/payment/mbway`, options);
  
      if (response.status === 200) {
        setIsSubmittedSuccess(true);
        setIsSubmittedError(false);
      } else {
        setIsSubmittedSuccess(false);
        setIsSubmittedError(true);
      }
    } catch (error) {
      setError(error);
      setIsSubmittedError(true);
      setIsSubmittedSuccess(false);
    }
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked); // update checkbox state
  };

  return (
    <div className="mt-5">
      <label className="block mb-2 text-sm font-bold text-gray-900">Email</label>
      <input
        type="email"
        name="email"
        placeholder="Insira o seu Email"
        required
        className="border mb-5 border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        onChange={(event) => setEmail(event.target.value)}
      />
      <label className="block mb-2 text-sm font-bold text-gray-900">Número de Telefone</label>
      <input
        type="tel"
        name="phone"
        placeholder="9XXXXXXXX"
        required
        className="border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        onChange={(event) => setAlias(event.target.value)}
      />
      <small>* Apenas números portugueses</small>

      {isSubmittedSuccess && (
        <div className="bg-green-100 mt-2 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
          <strong className="font-bold">Sucesso! </strong>
          <span className="block sm:inline">Dispõe agora de 4 minutos para aceitar o pagamento na sua aplicação MB Way</span>
          <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
            <svg className="fill-current h-6 w-6 text-green-500 cursor-pointer" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" onClick={() => setIsSubmittedSuccess(false)}><title>Fechar</title><path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" /></svg>
          </span>
        </div>
      )}
      {isSubmittedError && (
        <div className="bg-red-100 mt-2 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          <strong className="font-bold">Erro! </strong>
          <span className="block sm:inline">Insira o seu número de telefone e tente novamente</span>
          <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
            <svg className="fill-current h-6 w-6 text-red-500 cursor-pointer" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" onClick={() => setIsSubmittedError(false)}><title>Fechar</title><path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" /></svg>
          </span>
        </div>
      )}
      <div className="mt-10 text-gray-900 text-sm rounded focus:ring-blue-500 mb-5 focus:border-blfue-500 inline-flex w-full p-2.5">
        <input
          id="default-checkbox"
          type="checkbox"
          value=""
          className="w-4 h-4 mr-3 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          required
          onChange={handleCheckboxChange} // add onChange handler for checkbox
        />
        <h1>Tomei conhecimento que a informação constante deste formulário é guardada e tratada pela Eupago e dou consentimento para tal.</h1>
      </div>
      <div className="flex justify-end">
        <button
          onClick={handleSubmit}
          disabled={!isChecked}
          className={`ml-3 w-full sm:w-auto bg-green-cl hover:bg-opacity-80 text-white py-2 px-4 rounded ${!isChecked ? 'cursor-not-allowed opacity-50' : ''
            }`}
          type="submit"
        >
          Finalizar
        </button>
      </div>
    </div>
  )
}

export default MBWay