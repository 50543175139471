import React, { useContext, useState, useEffect } from 'react';
import Context from '../../context/Context';
import { URL } from '../../service/Service';
import { Link, useParams } from 'react-router-dom';
import { editFormElement } from '../../context/forms/FormsAction';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Breadcrumb from '../Breadcrumb';
import Header from '../TitleAndSubtitle';

const ChooseOptionEdit = ({ elementInfo }) => {
    const { idform, idformelement } = useParams();
    const { dispatch } = useContext(Context);
    const [label, setLabel] = useState(elementInfo.label);
    const [required, setRequired] = useState(elementInfo.required);
    const [options, setOptions] = useState(elementInfo.element_option);
    const [newOption, setNewOption] = useState('');
    const pages = [
        {
            name: 'Editar Elementos',
            href: `/forms/${idform}/formeditor`,
            current: false
        },
        {
            name: 'Editar',
            href: `/forms/${idform}/formelements/${idformelement}/edit`,
            current: true
        }
    ];

    useEffect(() => {
        setOptions(elementInfo.element_option);
        setLabel(elementInfo.label)
        setRequired(elementInfo.required)
    }, [elementInfo.element_option, elementInfo.label, elementInfo.required]);

    const updateHandler = () => {
        const url = `${URL}/api/forms/${idform}/formelements/${idformelement}`;
        const request = {
            method: 'PATCH',
            headers: {
                'Content-type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({ label, required, element_option: options }),
        };
        editFormElement(url, request, dispatch);
    };

    const handleDelete = async (index) => {
        const url = `${URL}/api/forms/${idform}/formelements/${idformelement}`;
        const newOptions = options.filter((_, i) => i !== index);
        setOptions(newOptions);
        const request = {
            method: 'PATCH',
            headers: {
                'Content-type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({ label, required, element_option: newOptions })
        };
        await editFormElement(url, request, dispatch);
    };

    const addOptionHandler = () => {
        setOptions([...options, newOption]);
        setNewOption('');
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        updateHandler();
    };

    return (

        <div className='p-5'>
        <Breadcrumb pages={pages} />
        <div className='mb-5'></div>
        <div className="flex flex-col md:flex-row justify-between items-center">
            <Header title="Editar Elemento" />
        </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
                <form onSubmit={handleSubmit} >

                    <div className="mb-4 mt-6">
                        <label htmlFor="default-input" className="p-2.5 ext-sm font-bold text-gray-900">
                            Adicionar Opção
                        </label>
                        <div className="flex">
                            <input
                                className="mt-1 w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                                type="text"
                                value={newOption}
                                onChange={e => setNewOption(e.target.value)}
                            />
                            <button className="hover:bg-opacity-80 text-black py-2" onClick={addOptionHandler}>
                                <AddBoxIcon />
                            </button>
                        </div>
                    </div>
                    <div className="flex flex-wrap">
                        {options.map((option, index) => (
                            <div
                                key={option}
                                className="flex items-center mb-8 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 px-2 relative"
                            >
                                <div className="bg-white p-6 rounded border items-center justify-center text-center shadow-md overflow-hidden w-full hover:shadow-lg transition duration-300 ease-in-out">
                                    <div className="mb-4 font-bold">{option}</div>
                                    <button
                                        type="button"
                                        onClick={() => handleDelete(index)}
                                        className="absolute top-0 left-0 bottom-0 shadow bg-red-500 hover:bg-opacity-80 text-white w-6 rounded"
                                    >
                                        <DeleteForeverIcon />
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </form>
                <form onSubmit={updateHandler} >

                    <div className="mb-4 mt-6">
                        <label htmlFor="default-input" className="p-2.5 ext-sm font-bold text-gray-900">
                            Legenda
                        </label>
                        <input
                            className="mt-1 w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                            type="text"
                            value={label}
                            onChange={e => setLabel(e.target.value)}
                        />
                    </div>
                    <div className="mb-10">
                        <label className="flex items-center">
                            <span className="font-bold text-gray-900 mr-3 ml-2.5">Campo Obrigatório</span>
                            <input
                                type="checkbox"
                                checked={required}
                                defaultChecked={required}
                                onChange={e => setRequired(e.target.checked)}
                            />
                        </label>
                    </div>
                    <div>
                        <button className="mr-4 bg-green-cl hover:bg-opacity-80 text-white py-2 px-4 rounded">
                            Guardar
                        </button>

                    </div>
                </form>
            </div>
        </div >
    );
};

export default ChooseOptionEdit;