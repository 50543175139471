import axios from 'axios';
import { URL } from '../../service/Service';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EyeIcon, EyeSlashIcon, QuestionMarkCircleIcon } from '@heroicons/react/20/solid'

const RegisterPage = () => {
  const [password, setPassword] = useState('');
  const [userPasswordConfirm, setUserPasswordConfirm] = useState('');
  const [client_id, setClient_id] = useState('');
  const [client_secret, setClient_secret] = useState('');
  const [email, setEmail] = useState('');

  const [error, setError] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const history = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const passwordRegex = /^(?=.*[A-Z])(?=.*\d).+$/;

    if (!passwordRegex.test(password)) {
      setError("Password must contain at least one capital letter and one number.");
      return;
    }

    if (password === userPasswordConfirm) {
      try {
        await axios.post(`${URL}/api/users/createcompanyuser`, {
          password: password,
          client_id: client_id,
          client_secret: client_secret,
          email: email,
        });
        history('/login')
      }
      catch (error) {
        setError("Dados inválidos");
      }
    }
    else {
      setError("Passwords não coincidem")
    }
  };

  return (
      <div className="flex min-h-screen items-center flex-col justify-center py-12 bg-eupago-bg sm:px-6 lg:px-8">
        <div className="mx-auto sm:mx-auto sm:w-full sm:max-w-md lg:max-w-lg xl:max-w-xl">
          <div className="bg-white justify-center py-12 px-20 shadow sm:rounded-lg sm:px-12 box-border">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
              <img
                className="mx-auto h-12 w-auto"
                src="https://sandbox.eupago.pt/backoffice/files/assets/images/auth/rebranding/Logo.png"
                alt='Eu pago'
              />
            </div>
            <div className='m-5'></div>
            <form className="space-y-6" onSubmit={handleSubmit}>
              <div>
                <label className="text-sm font-bold text-gray-700 flex">
                  Client ID
                  <a
                    href="https://eupago.atlassian.net/servicedesk/customer/portal/2/article/226983964?src=1848538827"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Veja como obter o seu Client ID"
                  >
                    <QuestionMarkCircleIcon className='w-5 h-5 ml-2' />
                  </a>
                </label>
                <div className="mt-1">
                  <input
                    id="client_id"
                    name="client_id"
                    required
                    onChange={e => setClient_id(e.target.value)}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:ring-green-cl focus:outline-none focus:border-blue-leve sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label className="text-sm font-bold text-gray-700 flex">
                  Client Secret
                  <a
                    href="https://eupago.atlassian.net/servicedesk/customer/portal/2/article/226983964?src=1848538827"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Veja como obter o seu Client Secret"
                  >
                    <QuestionMarkCircleIcon className='w-5 h-5 ml-2' />
                  </a>
                </label>
                <div className="mt-1">
                  <input
                    id="client_secret"
                    name="client_secret"
                    required
                    onChange={e => setClient_secret(e.target.value)}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:ring-green-cl focus:outline-none focus:border-blue-leve sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label className="text-sm font-bold text-gray-700 flex">
                  Email
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    required
                    onChange={e => setEmail(e.target.value)}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:ring-green-cl focus:outline-none focus:border-blue-leve sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="password" className="block text-sm font-bold text-gray-700">
                  Password
                </label>
                <div className="mt-1 relative">
                  <input
                    id="password"
                    name="password"
                    type={passwordVisible ? "text" : "password"}
                    required
                    onChange={e => setPassword(e.target.value)}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:ring-green-cl focus:outline-none focus:border-green-cl sm:text-sm"
                  />
                  <button
                    type="button"
                    onClick={() => setPasswordVisible(!passwordVisible)}
                    className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                  >
                    {passwordVisible ? <EyeSlashIcon className="h-5 w-5 text-gray-500" /> : <EyeIcon className="h-5 w-5 text-gray-500" />}
                  </button>
                </div>
              </div>
              <div>
                <label htmlFor="password2" className="block text-sm font-bold text-gray-700">
                  Confirm Password
                </label>
                <div className="mt-1 relative">
                  <input
                    id="password2"
                    name="password"
                    type={confirmPasswordVisible ? "text" : "password"}
                    required
                    onChange={e => setUserPasswordConfirm(e.target.value)}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:ring-green-cl focus:outline-none focus:border-green-cl sm:text-sm"
                  />
                  <button
                    type="button"
                    onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
                    className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                  >
                    {confirmPasswordVisible ? <EyeSlashIcon className="h-5 w-5 text-gray-500" /> : <EyeIcon className="h-5 w-5 text-gray-500" />}
                  </button>
                </div>
              </div>
              {error && <div className="text-red-500 text-center font-bold">{error}</div>}
              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md border border-transparent bg-green-claro py-2 px-4 font-bold font-medium text-white shadow-sm hover:bg-green-cl focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Registar
                </button>
              </div>

              <div className="text-sm text-center">
                <a href="/" className="font-medium text-indigo-600 hover:text-indigo-500 text-left">
                  Voltar ao Homepage
                </a>
              </div>
            </form>

          </div>
        </div>
      </div>
  )
}

export default RegisterPage;
