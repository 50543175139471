import React from 'react'

const MultilineInput = ({ label, required, onInputChange }) => {

    const handleInputChange = event => {
        onInputChange(label, event.target.value);
    };

    return (
        <>
            <div className="mb-6">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{label}
                {required && <span className="text-red-500 text-xl"> *</span>}

                </label>
                <textarea id="textarea" className="max-h-64 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" onChange={handleInputChange}
                    required={required} />
            </div>
        </>
    )
}

export default MultilineInput