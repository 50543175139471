import React from 'react'


const Title = ({ placeholder, label, required }) => {


    return (
        <>
            <div className="flex flex-col items-left text-left justify-center mb-10">
                <header className="font-bold leading-tight">
                    <div className="text-3xl font-bold mb-1 text-black">{label}</div>
                    <div className="font-normal text-sm mb-3 text-black">{placeholder}</div>
                </header>
                <hr className=''></hr>
            </div>
        </>
    )
}

export default Title