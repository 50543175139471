import React from 'react'

const Checkbox = ({  placeholder, label }) => {

    return (
        <>
            <div className="m-2 mr-5 w-full bg-white rounded shadow border p-4">
                {/* <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{label}</label> */}
            <div className="border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 mb-5 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                {label}
                <input id="default-checkbox" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 ml-4"></input>
            </div>
            </div>
        </>
    )
}

export default Checkbox