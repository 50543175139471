import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Context from '../../context/Context';
import { editFormElement } from '../../context/forms/FormsAction';
import { URL, URL_FRONT } from '../../service/Service';
import Header from '../TitleAndSubtitle';

const SelectEditWizard = ({ elementInfo, userRole }) => {
  const { idform, idformelement } = useParams();
  const { dispatch } = useContext(Context);
  const [label, setLabel] = useState(elementInfo.label);
  const [required, setRequired] = useState(elementInfo.required);
  const [options, setOptions] = useState(elementInfo.element_option);
  const [newOption, setNewOption] = useState('');
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setOptions(elementInfo.element_option);
    setLabel(elementInfo.label);
    setRequired(elementInfo.required);
  }, [elementInfo.element_option, elementInfo.label, elementInfo.required]);

  const updateHandler = async () => {
    const url = `${URL}/api/forms/${idform}/formelements/${idformelement}`;
    const request = {
      method: 'PATCH',
      headers: {
        'Content-type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({ label, required, element_option: options })
    };

    const response = await fetch(url, request);
    if (response.status === 200) {
      setSuccess(true);
      setTimeout(() => {
        navigate(`/forms/${idform}/formwizard/1`);
      }, 1000); // Delay for 1 second
    }

    editFormElement(url, request, dispatch);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    updateHandler();
    setTimeout(() => {
        if (userRole === 'ADMIN') {
            window.location.href = `${URL_FRONT}/admin/forms/${idform}/formwizard/1`;
        } else {
            window.location.href = `${URL_FRONT}/forms/${idform}/formwizard/1`;
        }
    }, 1000); // 1000 milliseconds = 1 second
};
  const handleDelete = async (index) => {
    const url = `${URL}/api/forms/${idform}/formelements/${idformelement}`;
    const newOptions = options.filter((_, i) => i !== index);
    setOptions(newOptions);
    const request = {
      method: 'PATCH',
      headers: {
        'Content-type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({ label, required, element_option: newOptions })
    };
    await editFormElement(url, request, dispatch);
  };

  const addOptionHandler = () => {
    setOptions([...options, newOption]);
    setNewOption('');
  };

  function handleKeyDown(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  }

  return (
    <div className='p-5'>
      <div className='mb-5'></div>
      <div className="flex flex-col md:flex-row justify-between items-center">
        <Header title="Editar Elemento" />
      </div>
      <div className="bg-white p-6 rounded-lg shadow-md">
        <form onSubmit={handleSubmit}>
          <div className="mb-4 mt-6">
            <label htmlFor="default-input" className="p-2.5 ext-sm font-bold text-gray-900">
              Adicionar Opção
            </label>
            <div className="flex">
              <input
                className="mt-1 w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                type="text"
                value={newOption}
                onChange={e => setNewOption(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              <button className="hover:bg-opacity-80 text-black py-2" onClick={addOptionHandler} type="button">
                <AddBoxIcon className='' />
              </button>
            </div>
          </div>
          <div className="flex flex-wrap">
            {options.map((option, index) => (
              <div
                key={option}
                className="flex items-center mb-8 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 px-2 relative"
              >
                <div className="bg-white p-6 rounded border items-center justify-center text-center shadow-md overflow-hidden w-full hover:shadow-lg transition duration-300 ease-in-out">
                  <div className="mb-4 font-bold">{option}</div>
                  <button
                    type="button"
                    onClick={() => handleDelete(index)}
                    className="absolute top-0 left-0 bottom-0 shadow bg-red-500 hover:bg-opacity-80 text-white w-6 rounded"
                  >
                    <DeleteForeverIcon />
                  </button>
                </div>
              </div>
            ))}
          </div>
          <div className="mb-4 mt-6">
            <label htmlFor="default-input" className="p-2.5 ext-sm font-bold text-gray-900">
              Legenda
            </label>
            <input
              className="mt-1 w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
              type="text"
              value={label}
              onChange={e => setLabel(e.target.value)}
            />
          </div>
          <div className="mb-10">
            <label className="flex items-center">
              <span className="font-bold text-gray-900 mr-3 ml-2.5">Campo Obrigatório</span>
              <input
                type="checkbox"
                checked={required}
                onChange={e => setRequired(e.target.checked)}
                defaultChecked={required}
              />
            </label>
          </div>
          <div class="flex justify-end">
            <button className="mr-4 bg-green-cl hover:bg-opacity-80 text-white py-2 px-4 rounded" type="submit">
              Guardar
            </button>
          </div>
        </form>
        {success && (
          <div class="fixed bottom-0 right-0 mb-4 mr-4 animate-slide-up">
            <div class="mt-10 relative flex flex-col sm:flex-row sm:items-center bg-white shadow rounded-md py-5 pl-6 pr-8 sm:pr-6">
              <button class="absolute top-0 right-0 px-2 py-1" type='button' onClick={() => setSuccess(false)}>x</button>
              <div class="flex flex-row items-center border-b sm:border-b-0 w-full sm:w-auto pb-4 sm:pb-0">
                <div class="text-green-500">
                  <svg class="w-6 sm:w-5 h-6 sm:h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                </div>
                <div class="text-sm font-medium ml-3">Sucesso:</div>
              </div>
              <div class="text-sm tracking-wide text-gray-500 mt-4 sm:mt-0 sm:ml-4">Dados guardados com sucesso!</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectEditWizard;
