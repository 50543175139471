import React, { useContext, useEffect, useState, useMemo, useCallback } from 'react';
import Context from '../../context/Context';
import { fetchFormElements, fetchForm, createFormElement, editFormElement } from '../../context/forms/FormsAction';
import { useParams, Link } from 'react-router-dom';
import { URL, URL_FRONT } from "../../service/Service";
import Element from "../../components/formElements/Element"
import Spinner from '../../components/Spinner';
import Breadcrumb from '../../components/Breadcrumb';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Title, TextRotationNone, CheckBox, ArrowDropDownCircle, TextFields, DateRange, Info, FormatListBulleted, Star, HorizontalRule, AttachMoney } from '@mui/icons-material';
import Header from '../../components/TitleAndSubtitle';

const FormEdit = () => {
    const { idform } = useParams()
    const { state, dispatch } = useContext(Context);
    const { loading, error, data } = state.formelements;
    const createElementURL = `${URL}/api/forms/${idform}/formelements`
    const [formElements, setFormElements] = useState([]);
    const [showNav, setShowNav] = useState(true);
    const toggleNav = () => setShowNav(prevState => !prevState);


    const pages = [
        {
            name: 'Formulários',
            href: `/forms/`,
            current: false
        },
        {
            name: 'Editor Formulário',
            href: `/forms/${idform}/formeditor`,
            current: true
        }
    ];

    const ELEMENTS = [
        { name: "Título", type: "title", icon: <Title /> },
        { name: "Caixa de Texto", type: "input", icon: <TextRotationNone /> },
        { name: "Caixa de Validação", type: "checkbox", icon: <CheckBox /> },
        { name: "Caixa de Seleção", type: "select", icon: <ArrowDropDownCircle /> },
        { name: "Caixa de Comentário", type: "multiline", icon: <TextFields /> },
        { name: "Data", type: "datepicker", icon: <DateRange /> },
        { name: "Caixa de Informação", type: "textblock", icon: <Info /> },
        { name: "Escolha Múltipla", type: "choose", icon: <FormatListBulleted /> },
        { name: "Avaliação", type: "rating", icon: <Star /> },
        { name: "Linha de Divisão", type: "divider", icon: <HorizontalRule /> },
        { name: "Campo de Preço", type: "price", icon: <AttachMoney /> },
    ];


    useEffect(() => {
        const url = `${URL}/api/forms/${idform}/formelements`;
        const url_form = `${URL}/api/forms/${idform}`;
        async function fetchData() {
            await fetchFormElements(url, {}, dispatch);
            await fetchForm(url_form, {}, dispatch);
            setFormElements(Object.values(data));
        }
        fetchData();
    }, [dispatch, idform]);

    useEffect(() => {
        setFormElements(Object.values(data));
    }, [data]);


    const handleChange = useCallback((input_id, event) => {
        setFormElements(prevFormElements =>
            prevFormElements.map(data => {
                const { element_type } = data;
                if (input_id === data.id) {
                    switch (element_type) {
                        case 'checkbox':
                            return { ...data, element_value: event.target.checked };
                        case 'datepicker':
                            return { ...data, element_value: event };
                        default:
                            return { ...data, element_value: event.target.value };
                    }
                }
                return data;
            })
        );
    }, []);

    const memoizedValue = useMemo(() => ({ handleChange }), [handleChange]);

    const updateHandler = (position, elementId) => {
        const url = `${URL}/api/forms/${idform}`;
        const request = {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({ position, elementId }),
        };
        editFormElement(url, request, dispatch);
    };

    const deleteHandler = (elementId) => {
        const url = `${URL}/api/forms/${idform}/formelements/${elementId}`;
        const request = {
            method: 'DELETE',
            headers: {
                'Content-type': 'application/json',
                'Accept': 'application/json',
            },
        };
        editFormElement(url, request, dispatch);
    };


    const createElement = (elementType) => {
        const request = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({ "type": elementType })
        }

        createFormElement(createElementURL, request, (response) => {
            // store the response in a variable
            const newElement = response.payload.data;
            setFormElements(formElements.concat(newElement))
            dispatch(data);
        });
    };
    
    const handleMoveUp = (index, elementId) => {
        setFormElements(prevFormElements => {
            const newIndex = index - 1;
            if (newIndex < 0) return prevFormElements; // cannot move up
            const newFormElements = [...prevFormElements];
            [newFormElements[index], newFormElements[newIndex]] = [newFormElements[newIndex], newFormElements[index]]; // swap elements
            return newFormElements;
        });
        updateHandler("up", elementId)
    };

    const handleMoveDown = (index, elementId) => {
        setFormElements((prevFormElements) => {
            const newIndex = index + 1;
            if (newIndex >= prevFormElements.length) return prevFormElements; // cannot move down
            const newFormElements = [...prevFormElements];
            [newFormElements[index], newFormElements[newIndex]] = [newFormElements[newIndex], newFormElements[index]]; // swap elements
            return newFormElements;
        });
        updateHandler("down", elementId)
    };

    const handleRemove = (index, elementId) => {
        setFormElements(prevFormElements => {
            const newFormElements = [...prevFormElements];
            newFormElements.splice(index, 1); // remove element at given index
            return newFormElements;
        });
        deleteHandler(elementId);
    };


    if (loading === true) {
        return (
            <Spinner></Spinner>
        )
    }

    return (
        <div className='p-5'>
            <Header title="Editor Formulário" />
            <div className="flex flex-col md:flex-row justify-between items-center">
                <Breadcrumb pages={pages} />
                <div>
                    <button className="mt-5 mr-6 md:mt-0 bg-green-cl hover:bg-opacity-80 text-white py-2 px-4 rounded"
                        onClick={() => window.open(`${URL_FRONT}/clientforms/${idform}`, "_blank")}
                    >Pré-Visualizar</button>
                    <button className="mt-5 md:mt-0 bg-green-cl hover:bg-opacity-80 text-white py-2 px-4 rounded"
                        onClick={() => window.open(`${URL_FRONT}/forms/${idform}/editform`, "_blank")}
                    >Definições</button>
                </div>
            </div>
            <div className='flex mt-10 w-full relative'>
                <div className={`flex rounded mr-1 border flex-col h-full ${showNav ? 'w-72' : 'w-16'} bg-white`}>
                    {showNav ? (
                        <>
                            <button className="w-full px-4 py-3 font-semibold text-gray-600 border-b border-gray-100 hover:text-gray-800 focus:outline-none" onClick={toggleNav}>
                                <div className='flex justify-between items-center'>
                                    <span className="text-lg font-bold">Elementos</span>
                                    <span className="text-gray-500 hover:text-gray-800">
                                        <KeyboardArrowLeftIcon />
                                    </span>
                                </div>
                            </button>
                            <nav className="flex-grow p-4">
                                <ul className="flex flex-col">
                                    {ELEMENTS.map((element) => (
                                        <li
                                            key={element.type}
                                            className="py-2 px-3 mb-3 rounded-lg hover:cursor-pointer hover:bg-gray-100 transition duration-200 ease-in-out"
                                            onClick={() => createElement(element.type)}
                                        >
                                            {element.icon}
                                            <span className="text-gray-700 text-sm ml-2">{element.name}</span>
                                        </li>

                                    ))}
                                </ul>
                            </nav>
                        </>
                    ) : (
                        <button className="" onClick={toggleNav}>
                            <small className='font-bold'>Elementos</small>
                            <span className=""><KeyboardArrowDownIcon /></span>
                        </button>
                    )}
                </div>
                <Context.Provider value={memoizedValue}>
                    <div className="w-full bg-white flex border rounded p-2.5">
                        <form className="w-full">
                            <div className=''>
                                {formElements ? formElements.map((formElement, i) => {
                                    return (
                                        <>
                                            <div key={i} className='flex' style={{ position: 'relative' }}>
                                                <button className="text-gray-700" type='button' onClick={() => handleRemove(i, formElement.id)} style={{ position: 'absolute', top: -4, left: -3 }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 24 24" stroke-width="1.5" stroke="red" class="w-6 h-6">
                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                    </svg>
                                                </button>
                                                <Element i={i} formElement={formElement} formId={idform} />
                                                <div className='flex flex-col ml-10'>
                                                    <button className="text-gray-700" type='button' onClick={() => handleMoveUp(i, formElement.id)} style={{ position: 'absolute', top: 10, right: 17 }}><ArrowUpwardIcon></ArrowUpwardIcon></button>
                                                    <button className="text-gray-700" type='button' onClick={() => handleMoveDown(i, formElement.id)} style={{ position: 'absolute', bottom: 10, right: 17 }}><ArrowDownwardIcon></ArrowDownwardIcon></button>
                                                    <Link to={`/forms/${idform}/formelements/${formElement.id}/edit`}>
                                                        <button className="text-gray-700" style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: 17 }}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                                            </svg>
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }) : null}
                            </div>
                        </form>
                    </div>
                </Context.Provider>
            </div>
        </div>
    );
}


export default FormEdit