import React, { useContext } from 'react'
import Context from '../../context/Context';



const ChooseOption = ({ id, label, element_option }) => {
    const { handleChange } = useContext(Context)

    return (
        <>
            <div className="m-2 mr-5 w-full bg-white rounded shadow border p-4">
                <div className="mb-6">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{label}</label>
                    {element_option.map((option) => (
                        <div key={option} className="mt-1">
                            <input
                                type="radio"
                                id={option}
                                name={id}
                                value={option}
                                onChange={event => handleChange(id, event)}
                                className="mr-2"
                            />
                            <label htmlFor={option}>{option}</label>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default ChooseOption