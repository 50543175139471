import React, { useContext, useEffect, useState } from 'react'
import Context from '../../context/Context';
import { fetchMetrics, fetchForms } from '../../context/forms/FormsAction';
import { URL } from "../../service/Service";
import { Link } from 'react-router-dom';
import { ClipboardDocumentListIcon, UserGroupIcon, EnvelopeIcon } from '@heroicons/react/24/outline';
import Header from '../../components/TitleAndSubtitle';

const AdminDashboard = () => {
  const { state, dispatch } = useContext(Context);

  const [formNumber, setFormNumber] = useState('');
  const [userNumber, setUserNumber] = useState('');
  const [replyNumber, setReplyNumber] = useState('');
  const urlMetrics = `${URL}/admin/metrics`;
  const urlForms = `${URL}/admin/forms`;

  useEffect(() => {
    fetchMetrics(urlMetrics, {}, dispatch);
    fetchForms(urlForms, {}, dispatch);
  }, [dispatch, urlMetrics, urlForms]);

  useEffect(() => {
    setFormNumber(state.metrics.data.formNumber);
    setUserNumber(state.metrics.data.userNumber);
    setReplyNumber(state.metrics.data.replyNumber);
  }, [state.metrics.data.formNumber, state.metrics.data.replyNumber, state.metrics.data.userNumber]);


  return (
    <div className='p-5'>
      <Header title="Dashboard" />
      <div className="flex flex-col md:flex-row items-center justify-center">
        <div className="bg-white shadow-lg rounded-lg p-6 md:w-80 h-40 lg:w-96">
          <div className="flex items-center justify-between mb-2">
            <h3 className="text-xl font-medium">Total Formulários:</h3>
            <ClipboardDocumentListIcon className='w-10' />
          </div>
          <p className="text-4xl font-bold">{formNumber}</p>
          <div className='w-full justify-items-end grid'>
          <Link to="/admin/forms" className="block text-sm font-medium text-blue-600 hover:underline">
          <button className="bg-green-cl hover:bg-opacity-80 text-white py-2 px-4 rounded">Todos os Formulários</button>
          </Link>
        </div>
        </div>
        <div className="bg-white shadow-lg rounded-lg p-6 m-4 md:w-80 h-40 lg:w-96">
          <div className="flex items-center justify-between mb-2">
            <h3 className="text-xl font-medium">Total Utilizadores:</h3>
            <UserGroupIcon className='w-10' />
          </div>
          <p className="text-4xl font-bold">{userNumber}</p>
          <div className='w-full justify-items-end grid'>
          <Link to="/admin/users" className="text-sm font-medium text-green-600 hover:underline">
          <button className="bg-green-cl hover:bg-opacity-80 text-white py-2 px-4 rounded">Todos os Utilizadores</button>
          </Link>
          </div>
        </div>
        <div className="bg-white shadow-lg rounded-lg p-6 m-4 md:w-80 h-40 lg:w-96">
          <div className="flex items-center justify-between mb-2">
            <h3 className="text-xl font-medium">Total Respostas:</h3>
            <EnvelopeIcon className='w-10' />
          </div>
          <p className="text-4xl font-bold">{replyNumber}</p>
        </div>
      </div>
    </div>
  )
}

export default AdminDashboard