import React from 'react'

const NotFound = () => {
    return (
        <section className="flex items-center p-16 dark:bg-gray-900 dark:text-gray-100">
            <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8 ">
                <div className="max-w-md text-center">
                    <h2 className="mb-8 font-extrabold text-9xl text-green-cl animate-pulse">
                        <span className="sr-only">Error</span>404
                    </h2>
                    <p className="text-2xl text-green-cl font-semibold md:text-3xl mb-8">Pedimos desculpa, mas não encontramos a página que procura</p>
                    <a rel="noopener noreferrer" href="/forms" className="px-8  py-3 font-semibold rounded dark:bg-violet-400 text-green-cl hover:text-green-claro">Voltar ao Euforms</a>
                </div>
            </div>
        </section>
    )
}

export default NotFound