import React from 'react'
import InputEdit from './InputEdit'
import CheckboxEdit from './CheckboxEdit'
import SelectEdit from './SelectEdit'
import MultilineEdit from './MultilineEdit'
import DatepickerEdit from './DatepickerEdit'
import TextblockEdit from './TextblockEdit'
import ChooseOptionEdit from './ChooseOptionEdit'
import RatingEdit from './RatingEdit'
import TitleEdit from './TitleEdit'
import PriceEdit from './PriceEdit'

const ElementEdit = ({ formElement: { element_option, element_type, element_value, id, label, links, photo, placeholder, selection, required } }) => {

    switch (element_type) {
        case 'input':
            return (
                <InputEdit
                    elementInfo={{ label, placeholder, required }}
                />
            )
        case 'multiline':
            return (
                <MultilineEdit
                    elementInfo={{ label, placeholder, required }}
                />
            )
        case 'select':
            return (
                <SelectEdit
                    elementInfo={{ label, placeholder, required, element_option }}
                />
            )
        case 'choose':
            return (
                <ChooseOptionEdit
                    elementInfo={{ label, placeholder, required, element_option }}
                />
            )
        case 'checkbox':
            return (
                <CheckboxEdit
                    elementInfo={{ label, placeholder, required }}
                />
            )
        case 'datepicker':
            return (
                <DatepickerEdit
                    elementInfo={{ label, required }}
                />
            )
        case 'textblock':
            return (
                <TextblockEdit
                    elementInfo={{ label, placeholder }}
                />
            )
        case 'rating':
            return (
                <RatingEdit
                    elementInfo={{ label, required }}
                />
            )
        case 'title':
            return (
                <TitleEdit
                    elementInfo={{ label, placeholder }}
                />
            )
        case 'price':
            return (
                <PriceEdit
                    elementInfo={{ label, element_value }}
                />
            )
        // case 'multi_input':
        //     return (
        //         <>multi_input</>
        //     )
        // case 'multi_input2':
        //     return (
        //         <>multi_input2</>
        //     )
        default:
            return null;
    }
}

export default ElementEdit