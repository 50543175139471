import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { Provider } from '../Context';
import formreducer from './FormsReducer';

const initialState = {
    forms: {
        loading: true,
        error: null,
        data: [],
    },
    form: {
        loading: true,
        error: null,
        data:{},
    },
    formelements: {
        loading: true,
        error: null,
        data:[],
    }, 

    formelement: {
        loading: true,
        error: null,
        data:{},
    },

    replies: {
        loading: true,
        error: null,
        data:[],
    },
    
    metrics: {
        loading: true,
        error: null,
        data:[],
    },

    users: {
        loading: true,
        error: null,
        data:[],
    },
    user: {
        loading: true,
        error: null,
        data: [],
    },
    reference:{
        loading: true,
        error: null,
        data: [],
    },
    references:{
        loading: true,
        error: null,
        data: {},
    }
};

const FormsProvider = (props) => {
    const [state, dispatch] = useReducer(formreducer, initialState);
    return (
        <Provider value={{
            state,
            dispatch
        }}>
            {props.children}
        </Provider>
    )
};

FormsProvider.propTypes = {
    children: PropTypes.node
};

export default FormsProvider;