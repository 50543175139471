import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { URL } from '../../service/Service';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid';

const ResetPasswordForm = () => {
    const { token } = useParams(); // Extract the token from the URL
    const [userEmail, setUserEmail] = useState('');
    const [userPass, setUserPass] = useState('');
    const [userPass2, setUserPass2] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (userPass !== userPass2) {
            setError('As palavras-passe não coincidem.');
            setMessage('');
            return;
        }
        try {
            const response = await axios.patch(`${URL}/api/users/resetpassword`, {
                email: userEmail,
                newPassword: userPass,
                token: token
            });
            setMessage(response.data.message || 'Palavra-passe alterada com sucesso');
            setError('');

            // Redirect to /login after 3 seconds
            setTimeout(() => {
                navigate('/login');
            }, 3000);
        } catch (error) {
            if (error.response && error.response.data) {
                setError(error.response.data.message || 'Ocorreu um erro enquanto tentamos fazer reset da sua palavra-passe');
            } else {
                setError('Ocorreu um erro enquanto tentamos fazer reset da sua palavra-passe');
            }
            setMessage('');
        }
    };

    return (
        <div className="flex min-h-screen items-center flex-col justify-center py-12 bg-eupago-bg sm:px-6 lg:px-8">
            <div className="mx-auto sm:mx-auto sm:w-full sm:max-w-md lg:max-w-lg xl:max-w-xl">
                <div className="bg-white justify-center py-12 px-20 shadow sm:rounded-lg sm:px-12 box-border">
                    <div className="sm:mx-auto sm:w-full sm:max-w-md">
                        <img
                            className="mx-auto h-12 w-auto"
                            src="https://sandbox.eupago.pt/backoffice/files/assets/images/auth/rebranding/Logo.png"
                            alt='Eupago'
                        />
                    </div>
                    <div className='m-5'></div>
                    <form className="space-y-6" onSubmit={handleSubmit}>
                        <div>
                            <label htmlFor="email" className="block text-sm font-bold text-gray-700">
                                Email
                            </label>
                            <div className="mt-1">
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    required
                                    value={userEmail}
                                    onChange={e => setUserEmail(e.target.value)}
                                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:ring-green-cl focus:outline-none focus:border-blue-leve sm:text-sm"
                                />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="password" className="block text-sm font-bold text-gray-700">
                                Nova palavra-passe
                            </label>
                            <div className="mt-1 relative">
                                <input
                                    id="password"
                                    name="password"
                                    type={passwordVisible ? "text" : "password"}
                                    required
                                    value={userPass}
                                    onChange={e => setUserPass(e.target.value)}
                                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:ring-green-cl focus:outline-none focus:border-blue-leve sm:text-sm"
                                />
                                <button
                                    type="button"
                                    onClick={() => setPasswordVisible(!passwordVisible)}
                                    className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                                >
                                    {passwordVisible ? <EyeSlashIcon className="h-5 w-5 text-gray-500" /> : <EyeIcon className="h-5 w-5 text-gray-500" />}
                                </button>
                            </div>
                        </div>
                        <div>
                            <label htmlFor="password2" className="block text-sm font-bold text-gray-700">
                                Repetir nova palavra-passe
                            </label>
                            <div className="mt-1 relative">
                                <input
                                    id="password2"
                                    name="password2"
                                    type={confirmPasswordVisible ? "text" : "password"}
                                    required
                                    value={userPass2}
                                    onChange={e => setUserPass2(e.target.value)}
                                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:ring-green-cl focus:outline-none focus:border-blue-leve sm:text-sm"
                                />
                                <button
                                    type="button"
                                    onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
                                    className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                                >
                                    {confirmPasswordVisible ? <EyeSlashIcon className="h-5 w-5 text-gray-500" /> : <EyeIcon className="h-5 w-5 text-gray-500" />}
                                </button>
                            </div>
                        </div>
                        <div className="flex items-center justify-between"></div>
                        <div>
                            <button
                                type="submit"
                                className="flex w-full justify-center rounded-md border border-transparent bg-green-claro py-2 px-4 font-bold font-medium text-white shadow-sm hover:bg-green-cl focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                Alterar palavra-passe
                            </button>
                        </div>
                        {message && <div className="text-sm text-center text-green-600">{message}</div>}
                        {error && <div className="text-sm text-center text-red-600">{error}</div>}
                        <div className="text-sm text-center">
                            <a href="/login" className="font-medium text-indigo-600 hover:text-indigo-500">
                                Voltar à página de login
                            </a>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default ResetPasswordForm;
