import React from 'react'

const MultilineInput = ({ label, required }) => {

    
    return (
        <>
            <div className="m-2 mr-5 w-full bg-white rounded shadow border p-4">
            <div className="mb-6">
                <label className="block mb-2 text-sm font-medium text-gray-900">{label}</label>
                <textarea id="textarea" className="max-h-64 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required={required}/>
            </div>
            </div>
        </>
    )
}

export default MultilineInput