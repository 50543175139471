import React from 'react'

const Price = ({ element_value, label }) => {

    const options = [];
    for (let i = 0; i <= 10; i++) {
        options.push(<option key={i} value={i}>{i}</option>);
    }


    return (
        <>
            <div className="m-2 mr-5 w-full bg-white rounded shadow border p-4">
                <div className='flex flex-col md:flex-row justify-between items-center md:items-start'>
                    <div className="bg-white p-2 mb-5 h-16 w-full lg:mr-5 rounded-md justify-between shadow-md border flex items-center">
                        <span className="text-gray-500 font-medium text-sm uppercase flex-grow">{label}</span>
                        <span className="text-gray-800 font-bold text-xl">{`${element_value}€`}</span>
                    </div>
                    <div className="bg-white h-16 p-2 mb-5 rounded-md shadow-md border justify-between w-full md:w-fit flex items-center">
                        <span className="text-gray-500 mr-2 font-medium text-sm uppercase tracking-wider">Quantidade</span>
                        <input
                            type="number"
                            className='text-xl border-transparent font-bold focus:border-transparent focus:ring-transparent w-20 md:w-24'
                            placeholder="0"
                            step="1"
                        />
                    </div>
                </div>
            </div>
        </>

    )
}

export default Price