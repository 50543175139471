import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { URL } from '../../service/Service';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid';

export default function LoginPage() {

  const [userEmail, setUserEmail] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [error, setError] = useState('');
  const [isRememberMeChecked, setIsRememberMeChecked] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const history = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const loginResponse = await axios.post(`${URL}/api/authenticate`, {
        userEmail: userEmail,
        userPassword: userPassword,
      });
      sessionStorage.setItem('jwt', loginResponse.data);
      if (isRememberMeChecked) {
        localStorage.setItem("userEmail", userEmail);
      }
      const decoded = jwtDecode(loginResponse.data);
      const role = decoded.authorities[0].authority;
      if (role === 'ADMIN') {
        history('/admin/dashboard')
      }
      else if (role === 'USER' || role === 'COMPANY') {
        history('/forms')
      }
      else {
        history('/')
      }
    } catch (error) {
      setError("Email ou Password Inválido");
    }
  };

  useEffect(() => {
    if (localStorage.getItem("userEmail")) {
      setUserEmail(localStorage.getItem("userEmail"));
    }
  }, []);

  return (
    <>
      <div className="flex min-h-screen items-center flex-col justify-center py-12 bg-eupago-bg sm:px-6 lg:px-8">
        <div className="mx-auto sm:mx-auto sm:w-full sm:max-w-md lg:max-w-lg xl:max-w-xl">
          <div className="bg-white justify-center py-12 px-20 shadow sm:rounded-lg sm:px-12 box-border">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
              <img
                className="mx-auto h-12 w-auto"
                src="https://sandbox.eupago.pt/backoffice/files/assets/images/auth/rebranding/Logo.png"
                alt='Eu pago'
              />
            </div>
            <div className='m-5'></div>
            <form className="space-y-6" onSubmit={handleSubmit}>
              <div>
                <label htmlFor="email" className="block text-sm font-bold text-gray-700">
                  Email
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    value={userEmail}
                    onChange={e => setUserEmail(e.target.value)}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:ring-green-cl focus:outline-none focus:border-blue-leve sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-bold text-gray-700">
                  Password
                </label>
                <div className="mt-1 relative">
                  <input
                    id="password"
                    name="password"
                    type={passwordVisible ? "text" : "password"}
                    autoComplete="current-password"
                    required
                    value={userPassword}
                    onChange={e => setUserPassword(e.target.value)}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:ring-green-cl focus:outline-none focus:border-green-cl sm:text-sm"
                  />
                  <button
                    type="button"
                    onClick={() => setPasswordVisible(!passwordVisible)}
                    className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                  >
                    {passwordVisible ? <EyeSlashIcon className="h-5 w-5 text-gray-500" /> : <EyeIcon className="h-5 w-5 text-gray-500" />}
                  </button>
                </div>
              </div>
              {error && <div className="text-red-500 text-center font-bold">{error}</div>}
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    checked={isRememberMeChecked}
                    onChange={() => setIsRememberMeChecked(!isRememberMeChecked)}
                  />
                  <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                    Lembrar
                  </label>
                </div>

                <div className="text-sm">
                  <a href="/resetpassword" className="font-medium text-indigo-600 hover:text-indigo-500">
                    Esqueceu a palavra-passe?
                  </a>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md border border-transparent bg-green-claro py-2 px-4 font-bold font-medium text-white shadow-sm hover:bg-green-cl focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Sign in
                </button>
              </div>
              <div className="text-sm text-center">
                <a href="/" className="font-medium text-indigo-600 hover:text-indigo-500">
                  Voltar ao Homepage
                </a>
              </div>
            </form>

          </div>
        </div>
      </div>
    </>
  )
}
