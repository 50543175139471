import React from 'react'
import Method from './paymentMethods/Method'

const CheckoutArea = ({method, value, api, formReplies, fixedValue, itemPurchases}) => {
  return (
    <>
        <Method method={method} value={value} api={api} formReplies={formReplies} fixedValue={fixedValue} itemPurchases={itemPurchases}/>
    </>
  )
}

export default CheckoutArea