import React, { useContext, useEffect, useState } from 'react';
import Context from '../../context/Context';
import { fetchForms, createForm, deleteForm } from '../../context/forms/FormsAction';
import { useNavigate } from 'react-router-dom';
import { URL, URL_FRONT } from "../../service/Service";
import Header from "../../components/TitleAndSubtitle";
import Spinner from '../../components/Spinner';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';

const Forms = () => {

    const { state, dispatch } = useContext(Context);
    const { forms } = state;
    const { loading, error, data } = forms;
    const [formsMap, setFormsMap] = useState(Object.values(data).reverse());
    const url = `${URL}/api/forms`;
    const itemsPerPage = 5;
    const history = useNavigate();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [formIdToDelete, setFormIdToDelete] = useState(null);
    const [value, setValue] = useState('Escolha uma ação');
    const [copied, setCopied] = useState(false);

    console.log(state)

    //////////////////////SEARCH AND PAGINATION///////////////////////////////

    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        const isSmallScreen = window.innerHeight <= 600;
        setRowsPerPage(isSmallScreen ? 5 : 10);
    }, []);


    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
        setCurrentPage(1); // reset page number when search term changes
    };

    const filteredformsMap = formsMap.filter((item) => {
        return item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.userID.toLowerCase().includes(searchTerm.toLowerCase());
    });

    const lastRowIndex = currentPage * rowsPerPage;
    const firstRowIndex = lastRowIndex - rowsPerPage;
    const currentRows = filteredformsMap.slice(firstRowIndex, lastRowIndex);
    const totalPages = Math.ceil(filteredformsMap.length / rowsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const pageNumbers = [];

    // show the first page number
    pageNumbers.push(1);

    for (let i = currentPage - 2; i <= currentPage + 2; i++) {
        if (i > 1 && i < totalPages) {
            pageNumbers.push(i);
        }
    }

    // show the last page number
    pageNumbers.push(totalPages);

    ////////////////////////////////////////////////////////////////////////////////

    function handleCopy(copyurl) {
        navigator.clipboard.writeText(copyurl);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
    }

    useEffect(() => {
        fetchForms(url, {}, dispatch);
    }, [dispatch, url]);

    useEffect(() => {
        setFormsMap(Object.values(data).reverse());
    }, [data]);

    const createNewForm = () => {
        const request = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
        }

        createForm(url, request, (response) => {
            // store the response in a variable
            const newForm = response.payload.data;
            setFormsMap([newForm, ...formsMap]);
            dispatch(data);
            window.location.href = `${URL_FRONT}/forms/${newForm.id}/formwizard/1`
        });
    };

    const deleteHandler = (formId) => {
        const url = `${URL}/api/forms/${formId}`;
        const request = {
            method: 'DELETE',
            headers: {
                'Content-type': 'application/json',
                'Accept': 'application/json',
            },
        };
        deleteForm(url, request, (response) => {
            // Delete the response in a variable
            const deletedFormId = response.payload.data.id;
            setFormsMap(prevFormsMap => prevFormsMap.filter(form => form.id !== deletedFormId));
        });
    };

    const handleConfirmation = (confirm) => {
        if (confirm) {
            deleteHandler(formIdToDelete);
        }
        setShowConfirmation(false);
        setFormIdToDelete(null);
    };

    const handleActionSelect = (value) => {
        setValue('Escolha uma ação');
        if (value.startsWith('replies/')) {
            const formId = value.split('/')[1];
            history(`/forms/${formId}/replies`);
        } else if (value.startsWith('formeditor/')) {
            const formId = value.split('/')[1];
            history(`/forms/${formId}/formwizard/1`);
        } else if (value.startsWith('editform/')) {
            const formId = value.split('/')[1];
            history(`/forms/${formId}/formwizard/2`);
        } else if (value.startsWith('clientforms/')) {
            const formId = value.split('/')[1];
            history(`/clientforms/${formId}`);
        } else if (value.startsWith('delete/')) {
            const formId = value.split('/')[1];
            setFormIdToDelete(formId);
            setShowConfirmation(true);
        }
        else if (value.startsWith('copy/')) {
            const formId = value.split('/')[1];
            const urlToCopy = `${URL_FRONT}/clientforms/${formId}`
            handleCopy(urlToCopy)
        }
        else if (value.startsWith('copyid/')) {
            const formId = value.split('/')[1];
            handleCopy(formId)
        }


    }


    if (loading === true) {
        return (
            <Spinner></Spinner>
        )
    }


    return (
        <div className="p-5">
            <div className={showConfirmation ? 'fixed z-20 inset-0 overflow-y-auto' : 'hidden'}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div className="fixed inset-0 transition-opacity">
                        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                    </div>

                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                    <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                        <div className="sm:flex sm:items-start">
                            <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                <ExclamationCircleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                            </div>
                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                <h3 className="text-lg leading-6 font-medium text-gray-900">Apagar Formulário</h3>
                                <div className="mt-2">
                                    <p className="text-sm text-gray-500">Tem a certeza que pretende apagar este formulário?</p>
                                </div>
                            </div>
                        </div>
                        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                            <button onClick={() => handleConfirmation(true)} type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
                                Apagar
                            </button>
                            <button onClick={() => handleConfirmation(false)} type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm">
                                Cancelar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col md:flex-row justify-between items-center">
                <Header title="Formulários" subtitle="Os Seus Formulários" />
                <button className="mt-5 md:mt-0 bg-green-cl hover:bg-opacity-80 text-white py-2 px-4 rounded" onClick={createNewForm}>Criar Formulário</button>
            </div>
            <div className="my-4">
                <input className="border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    type="text" placeholder="Procurar por nome do formulário" value={searchTerm} onChange={handleSearch} />
            </div>
            <div className="overflow-auto rounded-md">
                <table className="table-auto w-full border">
                    <thead className=''>
                        <tr className="bg-green-cl">
                            <th className="px-4 py-2 text-left text-gray-100">Nome</th>
                            <th className="px-4 py-2 text-left text-gray-100">Data</th>
                            <th className="px-4 py-2 text-left text-gray-100">Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentRows.map((item) => (
                            <tr key={item.id} className="border-t border-gray-200">
                                <td className="px-4 py-1">{item.name}</td>
                                <td className="px-4 py-1">{item.timestamps[0]}</td>
                                <td className="px-6 py-1">
                                    <select
                                        className="block w-full px-3 py-2 text-sm leading-tight border border-gray-300 rounded shadow-sm focus:outline-none focus:border-green-400 focus:shadow-outline-green"
                                        value={value}
                                        onChange={(e) => handleActionSelect(e.target.value)}
                                    >
                                        <option value="" className="text-gray-400">Escolha uma ação</option>
                                        <option value={`clientforms/${item.id}`}>Ver Formulário</option>
                                        <option value={`formeditor/${item.id}`}>Editar Formulário</option>
                                        <option value={`editform/${item.id}`}>Definições Formulário</option>
                                        <option value={`copy/${item.id}`}>Copiar URL</option>
                                        <option value={`copyid/${item.id}`}>Copiar Identificador</option>
                                        <option value={`replies/${item.id}`}>Ver Respostas</option>
                                        <option value={`delete/${item.id}`}>Apagar</option>
                                    </select>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="flex justify-end mt-4">
                {totalPages > 1 && (
                    <nav>
                        <ul className="inline-flex">
                            {pageNumbers.map((number) => (
                                <li key={number} className={currentPage === number ? "px-2 py-1 bg-green-cl text-white rounded-md mx-1" : "px-2 py-1 bg-gray-200 text-gray-700 rounded-md mx-1"}>
                                    <button onClick={() => handlePageChange(number)} className="focus:outline-none">
                                        {number}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </nav>
                )}
            </div>
        </div >
    )
}

export default Forms


