import React from 'react';

const Price = ({ label, element_value }) => {


    return (
        <div className="bg-white p-2 mb-5 h-16 w-full lg:mr-5 rounded-md justify-between shadow-md border flex items-center">
            <span className="text-gray-500 font-medium text-sm uppercase flex-grow">{label}</span>
            <span className="text-gray-800 font-bold text-xl">{`${element_value}€`}</span>
        </div>
    );
};

export default Price;