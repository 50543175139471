import React, { useState, useEffect } from 'react'
import { URL } from '../../service/Service';


const Multibanco = ({ value, api, formReplies, fixedValue, itemPurchases }) => {
    const [result, setResult] = useState('');
    const [replyResult, setReplyResult] = useState('');
    const [error, setError] = useState('');
    const [isChecked, setIsChecked] = useState(false); // add state variable for checkbox
    const url = `${URL}/api/replies`;
    const parsedResponse = result ? JSON.parse(result) : {};
    const [email, setEmail] = useState('');


    const replyRequest = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify(formReplies),
    }

    const createNewReply = async () => {
        const response = await fetch(url, replyRequest);
        const replyData = await response.text();
        const newReplyResult = JSON.parse(replyData);
        setReplyResult(newReplyResult);
        return newReplyResult;
    };

    const handleSubmit = async event => {
        event.preventDefault();
        const newReplyResult = await createNewReply();
        handleFetch();
    };

    const handleFetch = async () => {
        try {
            const body = {
                "value": value,
                "api": api,
                // "identifier": newReplyResult.id,
                "email": email,
                "fixedValue": fixedValue,
                "itemPurchases" : itemPurchases,
                "formID" : formReplies.formID,
                "replyFields" : formReplies.replyFields,
            };
            const options = {
                method: 'POST',
                headers: { accept: 'application/json', 'content-type': 'application/json' },
                body: JSON.stringify(body),
            };
            const response = await fetch(`${URL}/api/payment/multibanco`, options);
            const result = await response.text();
            setResult(result);
        } catch (error) {
            setError(error);
        }
    };

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked); // update checkbox state
    };

    return (
        <div className="mt-5">
            <label className="block mb-2 text-sm font-bold text-gray-900">Email</label>
            <input
                type="email"
                name="email"
                placeholder="Insira o seu Email"
                required
                className="border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                onChange={(event) => setEmail(event.target.value)}
            />
            {result && (
                <div className="overflow-x-auto">
                    <table className="mt-4 w-full border-collapse">
                        <thead className="bg-gray-200">
                            <tr>
                                <th className="py-2 px-4 border">Mêtodo</th>
                                <th className="py-2 px-4 border">Referência</th>
                                <th className="py-2 px-4 border">Entidade</th>
                                <th className="py-2 px-4 border">Valor</th>
                            </tr>
                        </thead>
                        <tbody className="divide-y">
                            <tr className="text-center">
                                <td className="py-2 px-4 border flex justify-center">
                                    <img className="w-10" src="https://eupago.pt/images/tarifario-meios-08.webp" alt="Multibanco" />
                                </td>
                                <td className="py-2 px-4 border">{parsedResponse.reference}</td>
                                <td className="py-2 px-4 border">{parsedResponse.entity}</td>
                                <td className="py-2 px-4 border">{parsedResponse.value}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )}
            <div className="mt-10 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 inline-flex w-full p-2.5">
                <input
                    id="default-checkbox"
                    type="checkbox"
                    value=""
                    className="w-4 h-4 mr-3 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    required
                    onChange={handleCheckboxChange} // add onChange handler for checkbox
                />
                <h1>Tomei conhecimento que a informação constante deste formulário é guardada e tratada pela Eupago e dou consentimento para tal.</h1>
            </div>
            <div className="flex justify-end">
                <button
                    onClick={handleSubmit}
                    disabled={!isChecked}
                    className={`w-full sm:w-auto bg-green-cl hover:bg-opacity-80 text-white py-2 px-4 rounded ${!isChecked ? 'cursor-not-allowed opacity-50' : ''
                        }`}
                    type="submit"
                >
                    Finalizar
                </button>
            </div>
        </div>
    )
}

export default Multibanco