import React, { useContext, useState, useEffect } from 'react'
import Context from '../../context/Context';
import { URL } from "../../service/Service";
import { Link, useParams } from 'react-router-dom';
import { editFormElement } from '../../context/forms/FormsAction';
import Breadcrumb from '../Breadcrumb';
import Header from '../TitleAndSubtitle';


const TextblockEdit = ({ elementInfo }) => {
    const { idform, idformelement } = useParams()
    const { dispatch } = useContext(Context);
    const [label, setLabel] = useState(elementInfo.label);
    const [placeholder, setPlaceholder] = useState(elementInfo.placeholder);
    const pages = [
        {
            name: 'Editar Elementos',
            href: `/forms/${idform}/formeditor`,
            current: false
        },
        {
            name: 'Editar',
            href: `/forms/${idform}/formelements/${idformelement}/edit`,
            current: true
        }
    ];

    useEffect(() => {
        setPlaceholder(elementInfo.placeholder);
        setLabel(elementInfo.label)
    }, [elementInfo.placeholder, elementInfo.label]);


    const updateHandler = () => {
        const url = `${URL}/api/forms/${idform}/formelements/${idformelement}`;
        const request = {
            method: 'PATCH',
            headers: {
                'Content-type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({ placeholder, label }),
        };
        editFormElement(url, request, dispatch);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        updateHandler();
      };

    return (
        <div className='p-5'>
        <Breadcrumb pages={pages} />
        <div className='mb-5'></div>
        <div className="flex flex-col md:flex-row justify-between items-center">
            <Header title="Editar Elemento" />
        </div>

            <form onSubmit={handleSubmit} className="bg-white p-6 rounded-lg shadow-md">
                <div className="mb-4">
                    <label for="default-input" className="p-2.5 ext-sm font-bold text-gray-900">Legenda</label>
                    <input
                        className="mt-1 w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5" type="text"
                        onChange={e => setLabel(e.target.value)}
                        value={label}
                    />
                </div>
                <div className="mb-4">
                    <label for="default-input" className="p-2.5 ext-sm font-bold text-gray-900">Texto</label>

                    <textarea
                        className="max-h-64 mt-1 w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5" type="text"
                        style={{minHeight: "100px", height: "auto"}}
                        onChange={e => setPlaceholder(e.target.value)}
                        value={placeholder}
                    />
                    <small className="ml-3 text-sm text-gray-600">Máximo de 2000 caracteres</small>
                </div>
                <div>
                    <button className="mr-4 bg-green-cl hover:bg-opacity-80 text-white py-2 px-4 rounded">
                    Guardar
                    </button>

                </div>
            </form>
        </div>
    );
}

export default TextblockEdit