import { React, useState, useContext, useEffect } from "react";
import Context from '../../context/Context';
import { UserIcon } from '@heroicons/react/20/solid'
import { fetchUser } from '../../context/forms/FormsAction';
import Header from "../../components/TitleAndSubtitle";
import Breadcrumb from "../../components/Breadcrumb";
import { URL } from "../../service/Service";
import Spinner from "../../components/Spinner";
import { updateUserPassword } from '../../context/forms/FormsAction';

const UserPage = () => {
  const { state, dispatch } = useContext(Context);
  const url = `${URL}/api/users/user`;
  const { loading, error, data } = state.user;
  const userInfo = state.user.data;

  const pages = [
    {
      name: 'Dashboard',
      href: `/admin/dashboard`,
      current: false
    },
    {
      name: 'User',
      href: `/admin/user`,
      current: true
    },
  ];

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [success, setSuccess] = useState(false); // new state variable
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showPassword3, setShowPassword3] = useState(false);

  const handleCurrentPasswordChange = (e) => {
    setCurrentPassword(e.target.value);
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleRepeatPasswordChange = (e) => {
    setRepeatPassword(e.target.value);
  };

  const updateHandler = async ({ oldPassword, newPassword }) => {
    const url = `${URL}/api/users/changepassword`;
    const request = {
      method: 'PATCH',
      headers: {
        'Content-type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify({ oldPassword, newPassword }),
    };

    const response = await fetch(url, request);
    if (response.status === 200) {
      setSuccess(true);
    }

    updateUserPassword(url, request, dispatch);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (newPassword === repeatPassword) {
      // Passwords match, allow password change
      setPasswordsMatch(true);
      const passwords = {
        oldPassword: currentPassword,
        newPassword: newPassword
      };
      updateHandler(passwords);
    } else {
      // Passwords do not match
      setPasswordsMatch(false);
    }
  };


  useEffect(() => {
    fetchUser(url, {}, dispatch);
  }, [dispatch, url]);


  if (loading === true) {
    return (
      <Spinner></Spinner>
    )
  }


  return (
    <div className="p-5">
      <Breadcrumb pages={pages} />
      <div class="flex items-left flex-col md:flex-row w-full justify-left mt-5">
        <div class="max-w-md flex">
          <div class="bg-white shadow-md rounded-lg py-4 px-6 w-full">
            <div class="photo-wrapper p-4">
              <div class="w-42 h-42 rounded-full mx-auto bg-gray-200 flex items-center justify-center">
                <UserIcon className="text-green-cl" />
              </div>
            </div>
            <div class="p-4">
              <h3 class="text-center text-md text-gray-700 font-medium leading-8">
                <span class="whitespace-nowrap">
                  <b>Email: </b>
                  {userInfo.userEmail}</span></h3>
              <div class="text-center text-md text-gray-700 font-medium leading-8">
                <span class="whitespace-nowrap">
                  <p><b>Conta: </b>
                    {userInfo.userRole}</p>
                </span>
              </div>
              <div class="text-center text-md text-gray-700 font-medium leading-8">
                <span class="whitespace-nowrap">
                  <p>
                    <b>ID: </b>
                    {userInfo.id}
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white shadow-md rounded-lg py-4 px-6 w-full sm:ml-10 sm:mt-0 mt-4">
          <Header title="Mudar Palavra Passe" />
          <form onSubmit={handleSubmit}>
            <div className="mt-10">
              {/* Current password */}
              <label htmlFor="current-password-input" className="p-2.5 ext-sm font-bold text-gray-900">Password Atual:</label>
              <div className="flex items-center">
                <input
                  id="current-password-input"
                  className="mt-1 flex-grow bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                  type={showPassword1 ? "text" : "password"}
                  value={currentPassword}
                  onChange={handleCurrentPasswordChange}
                />
                <button
                  className="ml-2 text-gray-500"
                  type="button"
                  onClick={() => setShowPassword1(!showPassword1)}
                >
                  {showPassword1 ? "Esconder" : "Mostrar"}
                </button>
              </div>
              {/* New password */}
              <div className="mt-5"></div>
              <label htmlFor="new-password-input" className="p-2.5 ext-sm font-bold text-gray-900">Nova Password:</label>
              <div className="flex items-center">
                <input
                  id="new-password-input"
                  className="mt-1 w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                  type={showPassword2 ? "text" : "password"}
                  value={newPassword}
                  onChange={handleNewPasswordChange}
                />
                <button
                  className="ml-2 text-gray-500"
                  type="button"
                  onClick={() => setShowPassword2(!showPassword2)}
                >
                  {showPassword2 ? "Esconder" : "Mostrar"}
                </button>
              </div>
              {/* Repeat new password */}
              <div className="mt-5"></div>
              <label htmlFor="repeat-password-input" className="p-2.5 ext-sm font-bold text-gray-900">Repetir Nova Password:</label>
              <div className="flex items-center">
                <input
                  id="repeat-password-input"
                  className="mt-1 w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                  type={showPassword3 ? "text" : "password"}
                  value={repeatPassword}
                  onChange={handleRepeatPasswordChange}
                />
                <button
                  className="ml-2 text-gray-500"
                  type="button"
                  onClick={() => setShowPassword3(!showPassword3)}
                >
                  {showPassword3 ? "Esconder" : "Mostrar"}
                </button>
                </div>
                {/* Password match error message */}
                {!passwordsMatch && (
                  <div class="fixed bottom-0 right-0 mb-4 mr-4 animate-slide-up">
                    <div class="mt-10 relative flex flex-col sm:flex-row sm:items-center bg-white shadow rounded-md py-5 pl-6 pr-8 sm:pr-6">
                      <button class="absolute top-0 right-0 px-2 py-1" type='button' onClick={() => setPasswordsMatch(true)}>x</button>
                      <div class="flex flex-row items-center border-b sm:border-b-0 w-full sm:w-auto pb-4 sm:pb-0">
                        <div class="text-red-500">
                          <svg class="w-6 sm:w-5 h-6 sm:h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                        </div>
                        <div class="text-sm font-medium ml-3">Erro:</div>
                      </div>
                      <div class="text-sm tracking-wide text-gray-500 mt-4 sm:mt-0 sm:ml-4">Passwords não coincidem</div>
                    </div>
                  </div>)}
                {/* Change password button */}
                <div className="flex items-center justify-end mt-5">
                  <button
                    className="bg-green-cl hover:bg-opacity-80 text-white py-2 px-4 rounded"
                    type="submit"
                    onClick={() => handleSubmit}
                  >
                    Mudar Password
                  </button>
                </div>
              </div>
          </form>
        </div>
        {success && (
          <div class="fixed bottom-0 right-0 mb-4 mr-4 animate-slide-up">
            <div class="mt-10 relative flex flex-col sm:flex-row sm:items-center bg-white shadow rounded-md py-5 pl-6 pr-8 sm:pr-6">
              <button class="absolute top-0 right-0 px-2 py-1" type='button' onClick={() => setSuccess(false)}>x</button>
              <div class="flex flex-row items-center border-b sm:border-b-0 w-full sm:w-auto pb-4 sm:pb-0">
                <div class="text-green-500">
                  <svg class="w-6 sm:w-5 h-6 sm:h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                </div>
                <div class="text-sm font-medium ml-3">Sucesso:</div>
              </div>
              <div class="text-sm tracking-wide text-gray-500 mt-4 sm:mt-0 sm:ml-4">Password alterada!</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};


export default UserPage;
