import { Dialog, DialogTitle, DialogActions, Button, IconButton } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useStyles from '../styles/styles';

const LogoutButtonBlack = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const history = useNavigate();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleLogout = () => {
    sessionStorage.removeItem('jwt');
    history('/login');
  };

  return (
    <div className={classes.bottomIcon}>
      <IconButton onClick={handleClickOpen}>
        <h6>Logout</h6>
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Tem a certeza que quer sair?</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleLogout}>Log out</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default LogoutButtonBlack;