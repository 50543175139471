import React from 'react'


const TextBlock = ({ placeholder, label }) => {

  return (
    <>
      <div className="m-2 mr-5 w-full bg-white rounded shadow border p-4">
        <div className="mb-6">
          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{label}</label>
          <div className="mt-1 text-justify w-full border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:outline-none focus:border-blue-500 block p-2.5 h-fit">
            {placeholder}
          </div>
        </div>
      </div>
    </>

  );
};

export default TextBlock;





