import React from 'react'

const ChooseOption = ({ id, label, element_option, required, onInputChange }) => {
    const handleInputChange = event => {
        onInputChange(label, event.target.value);
    };
    return (
        <div className="mb-6">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{label}
            {required && <span className="text-red-500 text-xl"> *</span>}
</label>
            {element_option.map((option) => (
                <div key={option} className="mt-1">
                    <input
                        type="radio"
                        id={option}
                        name={id}
                        value={option}
                        onChange={handleInputChange}
                        className="mr-2"
                        
                    />
                    <label htmlFor={option}>{option}</label>
                </div>
            ))}
        </div>
    )
}

export default ChooseOption