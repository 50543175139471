import React from 'react'

const Select = ({ required, label, placeholder, element_option, onInputChange }) => {
    const handleInputChange = (event) => {
      onInputChange(label, event.target.value);
    };
  
    return (
      <div className="mb-6">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          {label}
          {required && <span className="text-red-500 text-xl"> *</span>}

        </label>
        <select
          id="options"
          className="mt-1 w-full border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:outline-none focus:border-blue-500 block p-2.5 h-fit"
          onChange={handleInputChange}
        >
          <option disabled selected value="">
            Selecionar uma opção
          </option>
          {element_option.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
    );
  };
  
  export default Select;
  