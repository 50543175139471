import {
    FETCH_FORMS_STARTED,
    FETCH_FORMS_SUCCESS,
    FETCH_FORMS_FAILURE,
    FETCH_FORM_STARTED,
    FETCH_FORM_SUCCESS,
    FETCH_FORM_FAILURE,
    FETCH_FORM_ELEMENTS_STARTED,
    FETCH_FORM_ELEMENTS_SUCCESS,
    FETCH_FORM_ELEMENTS_FAILURE,
    FETCH_FORM_ELEMENT_STARTED,
    FETCH_FORM_ELEMENT_SUCCESS,
    FETCH_FORM_ELEMENT_FAILURE,
    FETCH_REPLIES_STARTED,
    FETCH_REPLIES_SUCCESS,
    FETCH_REPLIES_FAILURE,
    FETCH_METRICS_STARTED,
    FETCH_METRICS_SUCCESS,
    FETCH_METRICS_FAILURE,
    FETCH_USERS_STARTED,
    FETCH_USERS_SUCCESS,
    FETCH_USERS_FAILURE,
    FETCH_USER_STARTED,
    FETCH_USER_SUCCESS,
    FETCH_USER_FAILURE,
    FETCH_REFERENCE_STARTED,
    FETCH_REFERENCE_SUCCESS,
    FETCH_REFERENCE_FAILURE,
    FETCH_REFERENCES_STARTED,
    FETCH_REFERENCES_SUCCESS,
    FETCH_REFERENCES_FAILURE,
} from "./FormsAction";

function formreducer(state, action) {
    switch (action.type) {
        case FETCH_FORMS_STARTED:
            return {
                ...state,
                forms: {
                    loading: true,
                    error: null,
                    data: [],
                },
            };
        case FETCH_FORMS_SUCCESS:
            return {
                ...state,
                forms: {
                    loading: false,
                    error: null,
                    data: { ...action.payload.data },
                },
            };
        case FETCH_FORMS_FAILURE:
            return {
                ...state,
                forms: {
                    loading: false,
                    error: action.payload.error,
                    data: [],
                },
            };
        case FETCH_FORM_STARTED:
            return {
                ...state,
                form: {
                    loading: true,
                    error: null,
                    data: [],
                },
            };
        case FETCH_FORM_SUCCESS:
            return {
                ...state,
                form: {
                    loading: false,
                    error: null,
                    data: { ...action.payload.data },
                },
            };
        case FETCH_FORM_FAILURE:
            return {
                ...state,
                form: {
                    loading: false,
                    error: action.payload.error,
                    data: [],
                },
            };
        case FETCH_FORM_ELEMENTS_STARTED:
            return {
                ...state,
                formelements: {
                    loading: true,
                    error: null,
                    data: [],
                },
            };
        case FETCH_FORM_ELEMENTS_SUCCESS:
            return {
                ...state,
                formelements: {
                    loading: false,
                    error: null,
                    data: { ...action.payload.data },
                },
            };
        case FETCH_FORM_ELEMENTS_FAILURE:
            return {
                ...state,
                formelements: {
                    loading: false,
                    error: action.payload.error,
                    data: [],
                },
            };
        case FETCH_FORM_ELEMENT_STARTED:
            return {
                ...state,
                formelement: {
                    loading: true,
                    error: null,
                    data: [],
                },
            };
        case FETCH_FORM_ELEMENT_SUCCESS:
            return {
                ...state,
                formelement: {
                    loading: false,
                    error: null,
                    data: { ...action.payload.data },
                },
            };
        case FETCH_FORM_ELEMENT_FAILURE:
            return {
                ...state,
                formelement: {
                    loading: false,
                    error: action.payload.error,
                    data: [],
                },
            };
        case FETCH_REPLIES_STARTED:
            return {
                ...state,
                replies: {
                    loading: true,
                    error: null,
                    data: [],
                },
            };
        case FETCH_REPLIES_SUCCESS:
            return {
                ...state,
                replies: {
                    loading: false,
                    error: null,
                    data: { ...action.payload.data },
                },
            };
        case FETCH_REPLIES_FAILURE:
            return {
                ...state,
                replies: {
                    loading: false,
                    error: action.payload.error,
                    data: [],
                },
            };
        case FETCH_METRICS_STARTED:
            return {
                ...state,
                metrics: {
                    loading: true,
                    error: null,
                    data: {},
                },
            };
        case FETCH_METRICS_SUCCESS:
            return {
                ...state,
                metrics: {
                    loading: false,
                    error: null,
                    data: { ...action.payload.data },
                },
            };
        case FETCH_METRICS_FAILURE:
            return {
                ...state,
                metrics: {
                    loading: false,
                    error: action.payload.error,
                    data: {},
                },
            };
        case FETCH_USERS_STARTED:
            return {
                ...state,
                users: {
                    loading: true,
                    error: null,
                    data: {},
                },
            };
        case FETCH_USERS_SUCCESS:
            return {
                ...state,
                users: {
                    loading: false,
                    error: null,
                    data: { ...action.payload.data },
                },
            };
        case FETCH_USERS_FAILURE:
            return {
                ...state,
                users: {
                    loading: false,
                    error: action.payload.error,
                    data: {},
                },
            };
        case FETCH_USER_STARTED:
            return {
                ...state,
                user: {
                    loading: true,
                    error: null,
                    data: {},
                },
            };
        case FETCH_USER_SUCCESS:
            return {
                ...state,
                user: {
                    loading: false,
                    error: null,
                    data: { ...action.payload.data },
                },
            };
        case FETCH_USER_FAILURE:
            return {
                ...state,
                user: {
                    loading: false,
                    error: action.payload.error,
                    data: {},
                },
            };
        case FETCH_REFERENCE_STARTED:
            return {
                ...state,
                reference: {
                    loading: true,
                    error: null,
                    data: {},
                },
            };
        case FETCH_REFERENCE_SUCCESS:
            return {
                ...state,
                reference: {
                    loading: false,
                    error: null,
                    data: { ...action.payload.data },
                },
            };
        case FETCH_REFERENCE_FAILURE:
            return {
                ...state,
                reference: {
                    loading: false,
                    error: action.payload.error,
                    data: {},
                },
            };
        case FETCH_REFERENCES_STARTED:
            return {
                ...state,
                references: {
                    loading: true,
                    error: null,
                    data: [],
                },
            };
        case FETCH_REFERENCES_SUCCESS:
            return {
                ...state,
                references: {
                    loading: false,
                    error: null,
                    data: action.payload.data,
                },
            };
        case FETCH_REFERENCES_FAILURE:
            return {
                ...state,
                references: {
                    loading: false,
                    error: action.payload.error,
                    data: [],
                },
            };
        default:
            return state;
    }
}

export default formreducer;
