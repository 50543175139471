import React, { useContext, useEffect, useState, useReducer } from 'react';
import Context from '../../context/Context';
import { editFormElement, fetchFormElement } from '../../context/forms/FormsAction';
import { useParams } from 'react-router-dom';
import { URL } from "../../service/Service";
import ElementEdit from "../../components/elementEdit/ElementEdit"

const ElementEditor = () => {
    const {idform, idformelement} = useParams()
    const [element, setElement] = useState();
    const {state, dispatch} = useContext(Context);
    const {loading, error, data} = state.formelement;


    useEffect(() => {
      const url = `${URL}/api/forms/${idform}/formelements/${idformelement}`;
      fetchFormElement(url, {} , dispatch);
      setElement(element)
  }, []);


    return (
        <>
        <ElementEdit formElement={data}/>
        </>
    )
  }
  
  export default ElementEditor