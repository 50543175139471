import React from 'react';
import Checkbox from './Checkbox';
import Select from './Select';
import Input from './Input';
import Datepicker from './Datepicker';
import MultilineInput from './MultilineInput';
import DoubleInput from './DoubleInput';
import ChooseOption from './ChooseOption';
import TextBlock from './TextBlock';
import StarRating from './StarRating';
import Title from './Title';
import Divider from './Divider';
import Price from './Price';


const Element = ({ formElement: { element_option, element_type, element_value, id, label, links, photo, placeholder, selection, cost, required }, nextFormElement, formId }) => {
    switch (element_type) {
        case 'input':
            return (
                <>
                    <Input
                        id={id}
                        label={label}
                        placeholder={placeholder}
                        element_value={element_value}
                        formId={formId}
                    />

                </>
            )
        case 'multiline':
            return (
                <>
                    <MultilineInput
                        id={id}
                        label={label}
                        placeholder={placeholder}
                        element_value={element_value}
                        formId={formId}
                    />
                </>)
        case 'select':
            return (
                <>
                    <Select
                        id={id}
                        label={label}
                        placeholder={placeholder}
                        element_value={element_value}
                        element_option={element_option}
                        formId={formId}
                    />

                </>)
        case 'choose':
            return (
                <>
                    <ChooseOption
                        id={id}
                        label={label}
                        placeholder={placeholder}
                        element_value={element_value}
                        element_option={element_option}
                        formId={formId}
                    />
                </>)
        case 'checkbox':
            return (
                <>
                    <Checkbox
                        id={id}
                        label={label}
                        element_value={element_value}
                        placeholder={placeholder}
                        formId={formId}
                    />

                </>)
        case 'datepicker':
            return (
                <>
                    <Datepicker
                        id={id}
                        label={label}
                        element_value={element_value}
                        placeholder={placeholder}
                        formId={formId}
                    />

                </>)
        case 'textblock':
            return (
                <>
                    <TextBlock
                        id={id}
                        label={label}
                        placeholder={placeholder}
                        element_value={element_value}
                        formId={formId}
                    />
                </>
            )
        case 'rating':
            return (
                <>
                    <StarRating
                        id={id}
                        label={label}
                        element_value={element_value}
                        formId={formId}
                    />

                </>)
        case 'title':
            return (
                <>
                    <Title
                        id={id}
                        label={label}
                        placeholder={placeholder}
                        formId={formId}
                    />

                </>)
        case 'divider':
            return (
                <>
                    <Divider
                        id={id}
                        formId={formId}
                    />

                </>)
        case 'price':
            return (
                <>
                    <Price
                        id={id}
                        label={label}
                        placeholder={placeholder}
                        element_value={element_value}
                        formId={formId}
                    />

                </>)
        // case 'double_element':
        //     if (nextFormElement !== undefined) {
        //         return (
        //             <>
        //                 <DoubleInput
        //                     id={id}
        //                     label={label}
        //                     placeholder={placeholder}
        //                     element_value={element_value}
        //                 />

        //                 <DoubleInput
        //                     id={nextFormElement.id}
        //                     label={nextFormElement.label}
        //                     placeholder={nextFormElement.placeholder}
        //                     element_value={nextFormElement.element_value}
        //                 />
        //                 <Link to={`/forms/${formId}/formelements/${id}/edit`}>
        //                     <button className="text-gray-700 ml-2">
        //                         <EditIcon />
        //                     </button>
        //                 </Link>
        //                 <Link to={`/forms/${formId}/formelements/${nextFormElement.id}/edit`}>
        //                     <button className="text-gray-700 ml-2">
        //                         <EditIcon />
        //                     </button>
        //                 </Link>
        //             </>
        //         )
        //     }

        default:
            return null;
    }
}

export default Element