import { Popover, Transition } from '@headlessui/react'
import { ChevronRightIcon, LinkIcon } from '@heroicons/react/20/solid'
import {
  Bars3Icon,
  ClockIcon,
  CreditCardIcon,
  DocumentChartBarIcon,
  LockOpenIcon,
  MapPinIcon,
  XMarkIcon
} from '@heroicons/react/24/outline'
import { Fragment, useState } from 'react'
import euforms from "../../images/euforms.png"
import euformsmall from "../../images/euformsmall.png"
import formlanding from "../../images/formlanding.png"
import form1 from '../../images/landingpage.svg';



import { URL_FRONT } from '../../service/Service';


const features = [
  {
    name: 'Métodos de pagamento Eupago',
    description: 'Integre métodos de pagamento da Eupago para que os seus clientes paguem online com segurança.',
    icon: CreditCardIcon,
  },
  {
    name: 'Partilhe facilmente',
    description: ' Partilhe e permita a resposta aos seus formulário de forma simples, através de um URL.',
    icon: LinkIcon,
  },
  {
    name: 'Recolha automatizada de dados',
    description: 'Acesso rápido ás respostas e fácil exportação para uma folha de cálculo',
    icon: DocumentChartBarIcon,
  },
  {
    name: 'Partilhe a localização',
    description: 'Consiga partilhar a localização do seu evento através das coordenadas do mesmo',
    icon: MapPinIcon,
  },
  {
    name: 'Criação e respostas em tempo real',
    description:
      'Crie um formulário pronto a ser preenchido em minutos',
    icon: ClockIcon,
  },
  {
    name: 'Acesso e utilização gratuita',
    description: 'Utilização gratuita para clientes Eupago, basta fazer o registo com ID de Cliente e Chave Secreta ',
    icon: LockOpenIcon,
  },
]
const blogPosts = [
  {
    id: 1,
    title: 'Intuitivo e fácil de usar',
    href: '#',
    date: 'Março 16, 2023',
    datetime: '2020-03-16',
    category: { name: 'Marketing' },
    preview:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto accusantium praesentium eius, ut atque fuga culpa, similique sequi cum eos quis dolorum.',
    author: {
      name: 'José Alfredo',
      imageUrl:
        'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      href: '#',
    },
    readingLength: '6 min',
  },
  {
    id: 2,
    title: 'Métodos de pagamento da Eupago vieram dar um toque extra face à concorrência',
    href: '#',
    date: 'Janeira 10, 2023',
    datetime: '2020-03-10',
    category: { name: 'Engenheira de Gestão Industrial' },
    preview:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit facilis asperiores porro quaerat doloribus, eveniet dolore. Adipisci tempora aut inventore optio animi., tempore temporibus quo laudantium.',
    author: {
      name: 'Sofia Domingues',
      imageUrl:
        'https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      href: '#',
    },
    readingLength: '4 min',
  },
  {
    id: 3,
    title: 'Facilidade e transversalidade',
    href: '#',
    date: 'Janeira 31, 2023',
    datetime: '2020-02-12',
    category: { name: 'Empreendedora', href: '#' },
    preview:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint harum rerum voluptatem quo recusandae magni placeat saepe molestiae, sed excepturi cumque corporis perferendis hic.',
    author: {
      name: 'Daniela Veloso',
      imageUrl:
        'https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      href: '#',
    },
    readingLength: '11 min',
  },
]
const footerNavigation = {
  social: [
    {
      name: 'Facebook',
      href: 'https://www.facebook.com/eupago.pt/',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 16 16" {...props}>
          <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
        </svg>
      ),
    },
    {
      name: 'Instagram',
      href: 'https://www.instagram.com/eupago.pt/',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 16 16" {...props}>
          <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
        </svg>
      ),
    },

    {
      name: 'LinkedIn',
      href: 'https://www.linkedin.com/company/eupago/mycompany/',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 16 16" {...props}>
          <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
        </svg>
      ),
    },
  ],
}

export default function IndexLanding() {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div className="bg-white">
      <div className="relative overflow-hidden">
        <Popover as="header" className="relative">
          <div className="bg-green-cl pt-6">
            <nav
              className="relative mx-auto flex max-w-7xl items-center justify-between px-4 sm:px-6"
              aria-label="Global"
            >
              <div className="flex flex-1 items-center">
                <div className="flex w-full items-center justify-between md:w-auto">
                  <a href="https://www.eupago.pt">
                    <span className="sr-only">Eupago</span>
                    <img
                      className="h-8 w-auto sm:h-10 hover:opacity-80"
                      src={euforms} alt=""
                    />
                  </a>
                  <div className="-mr-2 flex items-center md:hidden">
                    <Popover.Button className="focus-ring-inset inline-flex items-center justify-center rounded-md bg-cyan-500 p-2 text-white hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-white">
                      <span className="sr-only">Open main menu</span>
                      <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>

              </div>
              <div className="hidden md:flex md:items-center md:space-x-6">
                <a href="https://www.eupago.pt/faqs" className="text-base font-medium text-white hover:text-gray-300">
                  FAQS
                </a>
                <a
                  href={`${URL_FRONT}/login`}

                  className="inline-flex items-center rounded-md border border-transparent bg-cyan-500 px-4 py-2 text-base font-medium text-white hover:bg-cyan-900"
                >
                  Login
                </a>
              </div>
            </nav>
          </div>

          <Transition
            as={Fragment}
            enter="duration-150 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel focus className="absolute inset-x-0 top-0 origin-top transform p-2 transition md:hidden">
              <div className="overflow-hidden rounded-lg bg-gray-300 shadow-md ring-1 ring-black ring-opacity-5">
                <div className="flex items-center justify-between px-5 pt-4">
                  <div>
                    <img
                      className="h-8 w-auto"
                      src={euformsmall} alt=""
                    />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="inline-flex items-center justify-center rounded-md bg-green-cl p-2 text-gray-400 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-600">
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="pt-5 pb-6">

                  <div className="mt-2 px-5">
                    <a
                      href={`${URL_FRONT}/login`} className="block w-full rounded-md bg-green-cl text-white  from-teal-500 to-cyan-600 py-3 px-4 text-center font-medium  shadow hover:from-teal-600 hover:to-cyan-700"
                    >
                      Login                    </a>

                  </div>
                  <div className="mt-2 px-5">
                    <a
                      href={`${URL_FRONT}/register`} className="block w-full rounded-md bg-green-cl text-white  from-teal-500 to-cyan-600 py-3 px-4 text-center font-medium  shadow hover:from-teal-600 hover:to-cyan-700"
                    >
                      Registar Euforms                    </a>

                  </div>
                  <div className="mt-2 px-5">
                    <p className="text-center text-base font-medium text-gray-500">
                      <a href={`${URL_FRONT}/register`} className="text-gray-500 hover:underline text-sm ">
                        Se já é cliente Eupago, mas não está registado no Euforms, <span className='font-bold text-black'>registe-se já!</span>
                      </a>

                    </p>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>
        <main>
          <div className="bg-green-cl pt-10 sm:pt-16 lg:overflow-hidden lg:pt-8 lg:pb-14">
            <div className="mx-auto max-w-7xl lg:px-8">
              <div className="lg:grid lg:grid-cols-2 lg:gap-8">
                <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:flex lg:items-center lg:px-0 lg:text-left">
                  <div className="lg:py-10">
                    <a
                      href="https://www.eupago.pt/registo"
                      className="inline-flex items-center rounded-xl bg-cyan-500 p-1 pr-2 text-white hover:text-gray-200 sm:text-base lg:text-sm xl:text-base"
                    >
                      <span className="rounded-md bg-gradient-to-r from-teal-500 to-cyan-600 px-3 py-0.5 text-sm font-semibold leading-5 text-white">
                        Ainda não é cliente Eupago?
                      </span>
                      <span className="ml-4 text-sm font-thin">Aceda ao nosso site e torne-se cliente</span>
                      <ChevronRightIcon className="ml-2 h-5 w-5 text-white" aria-hidden="true" />
                    </a>
                    <h1 className="mt-4 text-4xl font-bold tracking-tight text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                      <span className="block">Fazer formulários</span>
                      <span className="block bg-clip-text pb-3 text-cyan-500 sm:pb-5">
                        nunca foi tão simples.
                      </span>
                    </h1>
                    <p className="text-base text-gray-300 sm:text-xl lg:text-lg xl:text-xl">
                      Crie formulários personalizáveis e receba pagamentos com facilidade através do Euforms, integrado diretamente com a Eupago!
                    </p>
                    <div className="mt-10 sm:mt-12">
                      <form action="#" className="sm:mx-auto sm:max-w-xl lg:mx-0">
                        <div className="sm:flex">

                          <div className="mt-3 sm:mt-0 w-full">
                            <a
                              href={`${URL_FRONT}/register`}
                              className="block w-full rounded-xl bg-cyan-600 py-3 px-4 font-medium text-white shadow hover:from-teal-600 hover:to-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-400 focus:ring-offset-2 focus:ring-offset-gray-900"
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseLeave}
                            >
                              <div className="flex items-center justify-between hover:text-gray-200">
                                <p className="mr-2 font-normal text-lg">Registe-se já e crie o seu primeiro formulário</p>
                                <ChevronRightIcon className="ml-2 h-5 w-5 text-white" aria-hidden="true" />
                              </div>
                            </a>

                          </div>
                        </div>
                        <p className="mt-3 text-sm text-gray-300 sm:mt-4 bottom-0">
                          Se já é cliente da Eupago, clique no botão acima e faça o seu registo gratuito.
                          Basta inserir o seu ID de Cliente e Chave Secreta encontrados no seu backoffice e definir a sua senha. <p></p>
                        </p>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="mt-12 -mb-16 sm:-mb-48 lg:relative lg:m-0">
                  <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0">
                    <img
                      className="w-full rounded-xl mt-8 lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto ml-20 lg:max-w-none"
                      src={form1}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Feature section with screenshot */}
          <div className="relative bg-gray-50 pt-16 sm:pt-24 lg:pt-32">
            <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
              <div>
                <h2 className="text-lg font-semibold text-cyan-600">Simples e rápido.</h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  Crie formulários sem complicações.
                </p>
                <p className="mx-auto mt-5 max-w-prose text-xl text-gray-500">
                  Personalize-os de acordo com as suas necessidades e agilize a captura de informações importantes com o <span className="font-bold text-black">Euforms</span>.
                </p>
              </div>
              <div className="mt-12 -mb-10 sm:-mb-24 lg:-mb-80">
                <img
                  className="rounded-lg shadow-xl ring-1 ring-black ring-opacity-5 border-black scale-80"
                  src={formlanding}
                  alt=""
                />
              </div>
            </div>
          </div>
          {/* Feature section with grid */}
          <div className="relative bg-white py-16 sm:py-24 lg:py-32">
            <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
              <h2 className="text-lg font-semibold text-cyan-600">Facilidade e Simplicidade.</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Todas as vantagens do nossa aplicação.
              </p>
              <p className="mx-auto mt-5 max-w-prose text-xl text-gray-500">
                Com uma interface intuitiva e recursos poderosos, pode criar formulários personalizados em questão de minutos. Nunca foi tão fácil criar os seus formulários.
              </p>
              <div className="mt-12 ">
                <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                  {features.map((feature) => (
                    <div key={feature.name} className="pt-6">
                      <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8 h-[200px] border border-gray-200">
                        <div className="-mt-6 ">
                          <div>
                            <span className="inline-flex items-center justify-center rounded-md bg-white p-3 shadow-lg border border-gray-200">
                              <feature.icon className="h-6 w-6 text-teal-500" aria-hidden="true" />
                            </span>
                          </div>
                          <h3 className="mt-8 text-lg font-medium tracking-tight text-black">{feature.name}</h3>
                          <p className="mt-5 text-base  text-gray-500">{feature.description}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

            </div>
          </div>

          {/* Blog section
          <div className="relative bg-gray-50 py-16 sm:py-24 lg:py-32">
            <div className="relative">
              <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
                <h2 className="text-lg font-semibold text-cyan-600">Testemunhos</h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">O que os nossos clientes dizem de nós</p>
                <p className="mx-auto mt-5 max-w-prose text-xl text-gray-500">
                  Veja o que os nossos clientes têm a dizer sobre a sua experiência com a nossa plataforma!
                </p>
              </div>
              <div className="mx-auto mt-12 grid max-w-md gap-8 px-4 sm:max-w-lg sm:px-6 lg:max-w-7xl lg:grid-cols-3 lg:px-8">
                {blogPosts.map((post) => (
                  <div key={post.id} className="flex flex-col overflow-hidden rounded-lg shadow-lg">

                    <div className="flex flex-1 flex-col justify-between bg-white p-6">
                      <div className="flex-1">
                        <p className="text-sm font-medium text-cyan-600">
                          <a href={post.category.href} className="hover:underline">
                            {post.category.name}
                          </a>
                        </p>
                        <a href={post.href} className="mt-2 block">
                          <p className="text-xl font-semibold text-gray-900">{post.title}</p>
                          <p className="mt-3 text-base text-gray-500">{post.preview}</p>
                        </a>
                      </div>
                      <div className="mt-6 flex items-center">
                        <div className="flex-shrink-0">
                          <a href={post.author.href}>
                            <img className="h-10 w-10 rounded-full" src={post.author.imageUrl} alt={post.author.name} />
                          </a>
                        </div>
                        <div className="ml-3">
                          <p className="text-sm font-medium text-gray-900">
                            <a href={post.author.href} className="hover:underline">
                              {post.author.name}
                            </a>
                          </p>
                          <div className="flex space-x-1 text-sm text-gray-500">
                            <time dateTime={post.datetime}>{post.date}</time>
                            <span aria-hidden="true">&middot;</span>
                            <span>{post.readingLength} read</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div> */}
        </main>
        <footer className="bg-green-cl" aria-labelledby="footer-heading">
          <h2 id="footer-heading" className="sr-only">
            Footer
          </h2>
          <div className="mx-auto max-w-md px-4 pt-12  sm:max-w-7xl sm:px-6 lg:px-8 lg:pt-16">
            <div className="flex items-center justify-center">
              <div className="space-y-8 xl:col-span-1">
                <div className="flex space-x-6">
                  <a href="https://eupago.pt/" className="text-gray-400">
                    <img
                      className="h-6 grayscale"
                      src={euformsmall}
                      alt="Eupago"
                      style={{ filter: 'grayscale(100%)' }}
                      onMouseOver={(e) => {
                        e.target.style.filter = 'grayscale(0%)';
                        e.target.style.cursor = 'pointer';
                      }}
                      onMouseOut={(e) => {
                        e.target.style.filter = 'grayscale(100%)';
                        e.target.style.cursor = 'default';
                      }}
                    />
                  </a>
                  {footerNavigation.social.map((item) => (
                    <a key={item.name} href={item.href} className="text-gray-400 hover:text-cyan-500">
                      <span className="sr-only">{item.name}</span>
                      <item.icon className="h-6 w-6" aria-hidden="true" />
                    </a>
                  ))}
                </div>
              </div>
            </div>
            <div className="mt-12 border-t border-gray-200 py-8">
              <p className="text-base text-gray-400 xl:text-center">
                &copy; {new Date().getFullYear()} Eupago, Inc. Todos os direitos reservados.
              </p>
            </div>
          </div>
        </footer>
      </div>
    </div>
  )
}