import React, { useContext, useEffect, useState, useCallback, useRef } from 'react';
import Context from '../../context/Context';
import { fetchForm, editForm } from '../../context/forms/FormsAction';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { URL, URL_FRONT } from "../../service/Service";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { jwtDecode } from 'jwt-decode';


// const options = ["", "Cartão de Crédito", "Multibanco", "Mb Way", "Edenred", "Paysafe Card", "Paysafe Cash", "Débitos Diretos", "Payshop", "Przelewy24", "Cofidis Pay", "Santander Consumer", "Bizum"];

const options = ["", "Cartão de Crédito", "Multibanco", "Mb Way"];


const paymentImages = {
    "Cartão de Crédito": "https://eupago.pt/images/tarifario-meios-12.webp",
    "Multibanco": "https://eupago.pt/images/tarifario-meios-08.webp",
    "Mb Way": "https://eupago.pt/images/tarifario-meios-01.webp",
    // "Edenred": "https://eupago.pt/images/tarifario-meios-02.webp",
    // "Paysafe Card": "https://eupago.pt/images/tarifario-meios-03.webp",
    // "Paysafe Cash": "https://eupago.pt/images/tarifario-meios-04.webp",
    // "Débitos Diretos": "https://eupago.pt/images/tarifario-meios-05.webp",
    // "Payshop": "https://eupago.pt/images/tarifario-meios-06.webp",
    // "Przelewy24": "https://eupago.pt/images/tarifario-meios-07.webp",
    // "Cofidis Pay": "https://eupago.pt/images/tarifario-meios-11.webp",
    // "Santander Consumer": "https://eupago.pt/images/tarifario-meios-13.webp",
    // "Bizum": "https://eupago.pt/images/tarifario-meios-14.webp",
}

const FormWizard2 = () => {
    const { state, dispatch } = useContext(Context);
    const { form } = state;
    const { idform } = useParams();
    const [name, setName] = useState(form.data.name);
    const [info, setInfo] = useState(form.data.info)
    const [latitude, setLatitude] = useState(form.data.latitude);
    const [longitude, setLongitude] = useState(form.data.longitude);
    const [replyLimit, setReplyLimit] = useState(form.data.replyLimit);
    const [apiKey, setApiKey] = useState(form.data.apiKey);
    const [paymentOptions, setPaymentOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState();
    const [isFormValid, setIsFormValid] = useState(false);
    const [replyToggle, setReplyToggle] = useState();
    const token = sessionStorage.getItem('jwt');
    const userRole = jwtDecode(token).authorities[0].authority;
    const location = useLocation();
    const navigate = useNavigate()
    console.log(replyLimit)

    useEffect(() => {
        const isAdminPath = location.pathname.startsWith('/admin/forms/');
        const isFormWizard1Path = location.pathname.endsWith('/formwizard/2');

        if (isAdminPath && isFormWizard1Path && userRole !== 'ADMIN') {
            navigate('/login'); // Redirect to login screen if not ADMIN and accessing admin path
        }
    }, [location.pathname, navigate, userRole]);

    useEffect(() => {
        const url = `${URL}/api/forms/${idform}`;
        fetchForm(url, {}, dispatch);
    }, [dispatch, idform]);

    useEffect(() => {
        setPaymentOptions(form.data?.paymentOptions ?? []);
    }, [form.data?.paymentOptions]);

    useEffect(() => {
        if (name !== "" && apiKey !== "" && paymentOptions.length > 0) {
            setIsFormValid(true)
        }
    }, [name, apiKey, paymentOptions]);

    useEffect(() => {
        setReplyToggle(form.data.replyLimit > 0);
    }, [form.data.replyLimit]);

    useEffect(() => {
        setReplyLimit(form.data.replyLimit || 0);
    }, [form.data.replyLimit]);

    const updateHandler = useCallback(() => {
        const url = `${URL}/api/forms/${idform}`;
        const request = {
            method: "PATCH",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify({
                name: name,
                paymentOptions: paymentOptions,
                apiKey: apiKey,
                info: info,
                latitude: latitude,
                longitude: longitude,
                replyLimit: replyLimit,
            }),
        };
        editForm(url, request, dispatch);
        window.location.reload();
    }, [idform, name, paymentOptions, apiKey, info, latitude, longitude, replyLimit, dispatch]);


    const handleSubmit = useCallback(
        (event) => {
            event.preventDefault();
            updateHandler();
            if (userRole === 'ADMIN') {
                window.location.href = `${URL_FRONT}/admin/forms/${idform}/formwizard/3`;
            } else {
                window.location.href = `${URL_FRONT}/forms/${idform}/formwizard/3`;
            }
        },
        [updateHandler, idform]
    );

    const handleNameChange = useCallback((event) => {
        setName(event.target.value);
    }, []);

    const handleInfoChange = useCallback((event) => {
        setInfo(event.target.value);
    }, []);

    const handleApiKeyChange = useCallback((event) => {
        setApiKey(event.target.value);
    }, []);

    const handleLatitudeChange = useCallback((event) => {
        setLatitude(event.target.value);
    }, []);

    const handleLongitudeChange = useCallback((event) => {
        setLongitude(event.target.value);
    }, []);


    const handleOptionChange = useCallback((event) => {
        setSelectedOption(event.target.value);
    }, []);

    const handleAddOption = () => {
        if (selectedOption && !paymentOptions.includes(selectedOption)) {
            setPaymentOptions([...paymentOptions, selectedOption]);
            setSelectedOption("");
        }
    };

    const handleDeleteOption = (optionToDelete) => {
        const newOptions = paymentOptions.filter((option) => option !== optionToDelete);
        setPaymentOptions(newOptions);
    }

    const handleReplyLimitOption = useCallback((event) => {
        const value = parseInt(event.target.value);
        setReplyLimit(value);
    }, []);

    const handleButtonClickBack = () => {
        if (userRole === 'ADMIN') {
            navigate(`/admin/forms/${idform}/formwizard/1`);
        } else {
            navigate(`/forms/${idform}/formwizard/1`);
        }
    };

    const handleCheckboxChange = () => {
        setReplyToggle(prevState => !prevState);
        if (!replyToggle) {
            setReplyLimit(0);
        }
    };

    return (
        <div className="p-5">
            <form onSubmit={handleSubmit}>
                <div>
                    <div className="flex justify-between items-center mb-10">
                        <button className="mt-5 md:mt-0 bg-green-cl hover:bg-opacity-80 text-white text-xs py-2 px-4 rounded"
                            onClick={handleButtonClickBack}

                            type="button"
                        >Anterior</button>
                        <div className='w-full ml-10 mr-10 mt-8'>
                            <div className="overflow-hidden rounded-full bg-gray-200">
                                <div className="h-2 w-1/2 rounded-full bg-green-cl"></div>
                            </div>

                            <ol className="mt-4 grid grid-cols-3 text-sm font-medium text-gray-500">
                                <li className="flex items-center justify-start text-black">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-4">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                    </svg>

                                    <span className="hidden sm:inline font-bold"> Editor </span>

                                </li>

                                <li className="flex items-center justify-center text-black font-bold">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-4">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z" />
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                    </svg>
                                    <span className="hidden sm:inline"> Definições </span>
                                </li>
                                <li className="flex items-center justify-end text-black">

                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-4">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5" />
                                    </svg>

                                    <span className="hidden sm:inline"> Upload Imagem </span>
                                </li>
                            </ol>
                        </div>
                        <button className="mt-5 md:mt-0 bg-green-cl hover:bg-opacity-80 text-white text-xs py-2 px-4 rounded"
                            type="submit"
                        >Seguinte</button>
                    </div>
                </div>
                <div className="mt-2 bg-white p-6 rounded border shadow-md">
                    <div className="mb-4">
                        <label htmlFor="default-input" className="block font-bold text-gray-900">Nome do Formulário:</label>
                        <input
                            className="w-full border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                            type="text"
                            defaultValue={form.data.name}
                            onChange={handleNameChange}
                            required
                        />
                    </div>
                    <div className='mb-8'></div>
                    <div className="mb-4">
                        <label htmlFor="default-input" className="block font-bold text-gray-900">Chave API:</label>
                        <input
                            className="w-full border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                            type="text"
                            defaultValue={form.data.apiKey}
                            onChange={handleApiKeyChange}
                            required
                        />
                    </div>
                    <div className='mb-8'></div>
                    {/* <div className="mb-4 flex items-center">
                        <input
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 ml-4"
                            id="toggle-reply"
                            type="checkbox"
                            checked={replyToggle}
                            onChange={handleCheckboxChange}
                        />
                    </div> */}
                    <div className="mb-4">
                        <label htmlFor="default-input" className="block font-bold text-gray-900">Limite de Referências Pagas?</label>
                        <input
                            className="w-full border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                            type="number"
                            min={0}
                            max={100000}
                            defaultValue={form.data.replyLimit}
                            placeholder="Insira o valor do Limite de Referências Pagas"
                            onChange={handleReplyLimitOption}
                            required
                        />
                        <small>* Deixar o valor a 0 caso não queira limite</small>
                    </div>
                    <div className='mb-8'></div>

                    {/* <div>
                        <label htmlFor="default-input" className="block font-bold text-gray-900">Informação do Formulário:</label>
                        <textarea
                            className="max-h-64 h-20 w-full border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                            type="text"
                            defaultValue={form.data.info}
                            onChange={handleInfoChange}
                        />
                        <small>Aceita tags HTML como <b>{'<b>'}</b> texto exemplo<b>{'</b>'}</b> para negrito e <b>{'<p>'}</b> texto exemplo<b>{'</p>'}</b> para parágrafo.</small>
                        <div className='mb-8'></div>
                    </div>
                    <div className="flex flex-col md:flex-row ">
                        <div className="w-full md:w-1/2 md:mr-2">
                            <label htmlFor="default-input" className="block font-bold text-gray-900">Latitude:</label>
                            <input
                                className="w-full border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                                type="text"
                                defaultValue={form.data.latitude}
                                onChange={handleLatitudeChange}
                            />
                        </div>
                        <div className="w-full md:w-1/2 md:ml-2 mt-4 md:mt-0">
                            <label htmlFor="default-input" className="block font-bold text-gray-900">Longitude:</label>
                            <input
                                className="w-full border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                                type="text"
                                defaultValue={form.data.longitude}
                                onChange={handleLongitudeChange}
                            />
                        </div>
                    </div>
                    <small className='mb-4'>Adicione as coordenadas do seu Evento para visualizar o mapa do local, mantenha os valores a 0 para não mostrar o mapa.</small>
                    <div className='mb-8'></div> */}
                    <div>
                        <div className="mb-4">
                            <label htmlFor="default-input" className="block font-bold text-gray-900">
                                Adicionar Métodos de Pagamento:
                            </label>
                            <div className='flex'>
                                <select
                                    value={selectedOption}
                                    onChange={handleOptionChange}
                                    className="mr-2 w-full bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                                >
                                    {options.map((option) => (
                                        <option key={option} value={option}>
                                            {option || "Escolha um Método"}
                                        </option>
                                    ))}
                                </select>
                                <button type="button" className="w-full sm:w-auto mt-4 sm:mt-0 bg-green-cl hover:bg-opacity-80 text-white h-10 px-4 rounded" onClick={handleAddOption}>+</button>
                            </div>
                        </div>
                    </div>
                    <div className='mb-8'></div>
                    {paymentOptions.length > 0 && (
                        <label htmlFor="default-input" className="block font-bold text-gray-900">
                            Métodos Ativos:
                        </label>
                    )}                    <div className='mb-5'></div>
                    <div className="flex flex-wrap">
                        {paymentOptions.map((option) => (
                            <div
                                key={option}
                                className="flex items-center mb-8 w-full sm:w-1/2 md:w-1/3 lg:w-1/3 px-2 relative"
                            >
                                <div className="bg-white p-6 rounded border items-center justify-center text-center shadow-md overflow-hidden w-full hover:shadow-lg transition duration-300 ease-in-out">
                                    <div className="flex justify-center">
                                        <img
                                            src={paymentImages[option]}
                                            alt={option}
                                            className="h-10 mb-4"
                                        />
                                    </div>
                                    <div className="mb-4 font-bold">{option}</div>
                                    <button
                                        type="button"
                                        onClick={() => handleDeleteOption(option)}
                                        className="absolute top-0 left-0 bottom-0 shadow bg-red-500 hover:bg-opacity-80 text-white w-6 rounded"
                                    >
                                        <DeleteForeverIcon />
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div >
            </form >
        </div >

    );
};

export default FormWizard2