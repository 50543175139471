import React, { useContext, useState } from 'react'
import Context from '../../context/Context';

const Datepicker = ({ id, element_value, placeholder, label, formId }) => {
    const now = new Date();
    const [formattedDate, setFormattedDate] = useState(now.toISOString().substring(0, 10))
    const { handleChange } = useContext(Context)


    return (
        <>
            <div className="m-2 mr-5 w-full bg-white rounded shadow border p-4">
                <div className="mb-6">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{label}</label>
                    <input
                        type="date"
                        className="mt-1 w-full bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:outline-none focus:border-blue-500 block p-2.5 h-fit" value={formattedDate}
                        onChange={event => {
                            const date = new Date(event.target.value)
                            const dateFormatted = date.toISOString().substring(0, 10);
                            const dateToSave = date.getDate().toString().padStart(2, '0') + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' + date.getFullYear();
                            setFormattedDate(dateFormatted);
                            handleChange(id, dateToSave);
                        }}
                    />
                </div>
            </div>
        </>
    );
}

export default Datepicker;