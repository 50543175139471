import {makeHttpRequest, makeHttpRequestNoLogin, makeHttpRequestWithoutToken} from "../../service/Service";

export const FETCH_FORM_ELEMENTS_STARTED = "FETCH_FORM_ELEMENTS_STARTED";
export const FETCH_FORM_ELEMENTS_SUCCESS = "FETCH_FORM_ELEMENTS_SUCCESS";
export const FETCH_FORM_ELEMENTS_FAILURE = "FETCH_FORM_ELEMENTS_FAILURE";

export const FETCH_FORM_ELEMENT_STARTED = "FETCH_FORM_ELEMENT_STARTED";
export const FETCH_FORM_ELEMENT_SUCCESS = "FETCH_FORM_ELEMENT_SUCCESS";
export const FETCH_FORM_ELEMENT_FAILURE = "FETCH_FORM_ELEMENT_FAILURE";

export const FETCH_FORMS_STARTED = "FETCH_FORMS_STARTED";
export const FETCH_FORMS_SUCCESS = "FETCH_FORMS_SUCCESS";
export const FETCH_FORMS_FAILURE = "FETCH_FORMS_FAILURE";

export const FETCH_FORM_STARTED = "FETCH_FORM_STARTED";
export const FETCH_FORM_SUCCESS = "FETCH_FORM_SUCCESS";
export const FETCH_FORM_FAILURE = "FETCH_FORM_FAILURE";

export const SET_ELEMENT_PLACEHOLDER = "SET_ELEMENT_PLACEHOLDER";
export const SET_ELEMENT_LABEL = "SET_ELEMENT_LABEL";
export const SET_ELEMENT_REQUIRED = "SET_ELEMENT_REQUIRED";
export const EDIT_FORM_ELEMENT_SUCCESS = 'editFormElementSuccess';
export const EDIT_FORM_ELEMENT_FAILURE = 'editFormElementFailure';
export const EDIT_FORM_ELEMENT_STARTED = 'editFormElementStarted';

export const CREATE_FORM_ELEMENT_SUCCESS = 'changeFormEmailSuccess';
export const CREATE_FORM_ELEMENT_FAILURE = 'createFormEmailFailure';
export const CREATE_FORM_ELEMENT_STARTED = 'createFormEmailStarted';

export const CREATE_FORM_SUCCESS = 'createFormSuccess';
export const CREATE_FORM_FAILURE = 'createFormFailure';
export const CREATE_FORM_STARTED = 'createFormStarted';

export const CHANGE_FORM_EMAIL_SUCCESS = 'createFormSuccess';
export const CHANGE_FORM_EMAIL_FAILURE = 'createFormFailure';
export const CHANGE_FORM_EMAIL_STARTED = 'createFormStarted';

export const DELETE_FORM_SUCCESS = 'deleteFormSuccess';
export const DELETE_FORM_FAILURE = 'deleteFormFailure';
export const DELETE_FORM_STARTED = 'deleteFormStarted';

export const UPLOAD_SUCCESS = 'uploadSuccess';
export const UPLOAD_FAILURE = 'uploadFormFailure';
export const UPLOAD_STARTED = 'uploadFormStarted';

export const EDIT_FORM_SUCCESS = 'editFormElementSuccess';
export const EDIT_FORM_FAILURE = 'editFormElementFailure';
export const EDIT_FORM_STARTED = 'editFormElementStarted';

export const CREATE_REPLY_SUCCESS = 'createReplySuccess';
export const CREATE_REPLY_FAILURE = 'createReplyFailure';
export const CREATE_REPLY_STARTED = 'createReplyStarted';

export const FETCH_REPLIES_SUCCESS = 'FETCH_REPLIES_SUCCESS';
export const FETCH_REPLIES_FAILURE = 'FETCH_REPLIES_FAILURE';
export const FETCH_REPLIES_STARTED = 'FETCH_REPLIES_STARTED';

export const FETCH_METRICS_SUCCESS = 'FETCH_METRICS_SUCCESS';
export const FETCH_METRICS_FAILURE = 'FETCH_METRICS_FAILURE';
export const FETCH_METRICS_STARTED = 'FETCH_METRICS_STARTED';

export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';
export const FETCH_USERS_STARTED = 'FETCH_USERS_STARTED';

export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';
export const FETCH_USER_STARTED = 'FETCH_USER_STARTED';

export const DELETE_USER_SUCCESS = 'deleteUserSuccess';
export const DELETE_USER_FAILURE = 'deleteUserFailure';
export const DELETE_USER_STARTED = 'deleteUserStarted';

export const UPDATE_PASSWORD_SUCCESS = 'updatePasswordSuccess';
export const UPDATE_PASSWORD_FAILURE = 'updatePasswordSuccess';
export const UPDATE_PASSWORD_STARTED = 'updatePasswordSuccess';

export const FETCH_REFERENCE_SUCCESS = 'FETCH_REFERENCE_SUCCESS';
export const FETCH_REFERENCE_FAILURE = 'FETCH_REFERENCE_FAILURE';
export const FETCH_REFERENCE_STARTED = 'FETCH_REFERENCE_STARTED';

export const FETCH_REFERENCES_SUCCESS = 'FETCH_REFERENCES_SUCCESS';
export const FETCH_REFERENCES_FAILURE = 'FETCH_REFERENCES_FAILURE';
export const FETCH_REFERENCES_STARTED = 'FETCH_REFERENCES_STARTED';


export function fetchForms(url, request, dispatch){
    const success = (res) => dispatch(fetchFormsSuccess(res));
    const failure = (err) => dispatch(fetchFormsFailure(err.message));
    makeHttpRequest(url, request, success, failure);

}

export function fetchFormsSuccess(form){
    return{
        type: FETCH_FORMS_SUCCESS,
        payload: {
            data: {...form}
        },
    };
}

export function fetchFormsFailure(message){
    return {
        type: FETCH_FORMS_FAILURE,
        payload: {
            error: message,
        },
    };
}

export function fetchForm(url, request, dispatch){
    const success = (res) => dispatch(fetchFormSuccess(res));
    const failure = (err) => dispatch(fetchFormFailure(err.message));
    makeHttpRequestNoLogin(url, request, success, failure);
}

export function fetchFormSuccess(form){
    return{
        type: FETCH_FORM_SUCCESS,
        payload: {
            data: {...form}
        },
    };
}

export function fetchFormFailure(message){
    return {
        type: FETCH_FORM_FAILURE,
        payload: {
            error: message,
        },
    };
}

export function fetchFormElements(url, request, dispatch){
    const success = (res) => dispatch(fetchFormElementsSuccess(res));
    const failure = (err) => dispatch(fetchFormElementsFailure(err.message));
    makeHttpRequestNoLogin(url, request, success, failure);
}

export function fetchFormElementsSuccess(formelements){
    return{
        type: FETCH_FORM_ELEMENTS_SUCCESS,
        payload: {
            data: {...formelements}
        },
    };
}

export function fetchFormElementsFailure(message){
    return {
        type: FETCH_FORM_ELEMENTS_FAILURE,
        payload: {
            error: message,
        },
    };
}

export function fetchFormElement(url, request, dispatch){
    const success = (res) => dispatch(fetchFormElementSuccess(res));
    const failure = (err) => dispatch(fetchFormElementFailure(err.message));
    makeHttpRequest(url, request, success, failure);
}

export function fetchFormElementSuccess(formelement){
    return{
        type: FETCH_FORM_ELEMENT_SUCCESS,
        payload: {
            data: {...formelement}
        },
    };
}

export function fetchFormElementFailure(message){
    return {
        type: FETCH_FORM_ELEMENT_FAILURE,
        payload: {
            error: message,
        },
    };
}

export function editFormElement(url, request, dispatch) {
    const success = (res) => dispatch(editFormElementSuccess(res));

    const failure = (err) => dispatch(editFormElementFailure(err.message));

    makeHttpRequest(url, request, success, failure);
}

export function editFormElementStarted() {
    return {
        type: EDIT_FORM_ELEMENT_STARTED,

    }
}

export function editFormElementSuccess(formElement) {
    return {
        type: EDIT_FORM_ELEMENT_SUCCESS,
        payload: {
            data: {...formElement}
        }
    }
}

export function editFormElementFailure(err) {
    return {
        type: EDIT_FORM_ELEMENT_FAILURE,
        payload: {
            error: err,
        },
    }
}

export function createFormElement(url, request, dispatch) {
    const success = (res) => dispatch(createFormElementSuccess(res));

    const failure = (err) => dispatch(createFormElementFailure(err.message));

    makeHttpRequest(url, request, success, failure);
}

export function createFormElementStarted() {
    return {
        type: CREATE_FORM_ELEMENT_STARTED,

    }
}

export function createFormElementSuccess(formElement) {
    return {
        type: CREATE_FORM_ELEMENT_SUCCESS,
        payload: {
            data: {...formElement}
        }
    }
}

export function createFormElementFailure(err) {
    return {
        type: CREATE_FORM_ELEMENT_FAILURE,
        payload: {
            error: err,
        },
    }
}

export function createForm(url, request, dispatch) {
    const success = (res) => dispatch(createFormSuccess(res));

    const failure = (err) => dispatch(createFormFailure(err.message));

    makeHttpRequest(url, request, success, failure);
}

export function createFormStarted() {
    return {
        type: CREATE_FORM_STARTED,

    }
}

export function createFormSuccess(formElement) {
    return {
        type: CREATE_FORM_SUCCESS,
        payload: {
            data: {...formElement}
        }
    }
}

export function createFormFailure(err) {
    return {
        type: CREATE_FORM_FAILURE,
        payload: {
            error: err,
        },
    }
}


export function changeFormEmail(url, request, dispatch) {
    const success = (res) => dispatch(changeFormEmailSuccess(res));

    const failure = (err) => dispatch(changeFormEmailFailure(err.message));

    makeHttpRequest(url, request, success, failure);
}

export function changeFormEmailStarted() {
    return {
        type: CHANGE_FORM_EMAIL_STARTED,

    }
}

export function changeFormEmailSuccess(email) {
    return {
        type: CHANGE_FORM_EMAIL_SUCCESS,
        payload: {
            data: {...email}
        }
    }
}

export function changeFormEmailFailure(err) {
    return {
        type: CHANGE_FORM_EMAIL_FAILURE,
        payload: {
            error: err,
        },
    }
}


export function deleteForm(url, request, dispatch) {
    const success = (res) => dispatch(deleteFormSuccess(res));

    const failure = (err) => dispatch(deleteFormFailure(err.message));

    makeHttpRequest(url, request, success, failure);
}

export function deleteFormStarted() {
    return {
        type: DELETE_FORM_STARTED,

    }
}

export function deleteFormSuccess(formElement) {
    return {
        type: DELETE_FORM_SUCCESS,
        payload: {
            data: {...formElement}
        }
    }
}

export function deleteFormFailure(err) {
    return {
        type: DELETE_FORM_FAILURE,
        payload: {
            error: err,
        },
    }
}

export function uploadFile(url, request, dispatch) {
    const success = (res) => dispatch(uploadFileSuccess(res));

    const failure = (err) => dispatch(uploadFileFailure(err.message));

    makeHttpRequest(url, request, success, failure);
}

export function uploadFileStarted() {
    return {
        type: UPLOAD_STARTED,

    }
}

export function uploadFileSuccess(file) {
    return {
        type: UPLOAD_SUCCESS,
        payload: {
            data: {...file}
        }
    }
}

export function uploadFileFailure(err) {
    return {
        type: UPLOAD_FAILURE,
        payload: {
            error: err,
        },
    }
}


export function editForm(url, request, dispatch) {
    const success = (res) => dispatch(editFormSuccess(res));

    const failure = (err) => dispatch(editFormFailure(err.message));

    makeHttpRequest(url, request, success, failure);
}

export function editFormStarted() {
    return {
        type: EDIT_FORM_STARTED,

    }
}

export function editFormSuccess(form) {
    return {
        type: EDIT_FORM_SUCCESS,
        payload: {
            data: {...form}
        }
    }
}

export function editFormFailure(err) {
    return {
        type: EDIT_FORM_FAILURE,
        payload: {
            error: err,
        },
    }
}

export function createReply(url, request, dispatch) {
    const success = (res) => dispatch(createReplySuccess(res));

    const failure = (err) => dispatch(createReplyFailure(err.message));

    makeHttpRequestWithoutToken(url, request, success, failure);
}

export function createReplyStarted() {
    return {
        type: CREATE_REPLY_STARTED,

    }
}

export function createReplySuccess(reply) {
    return {
        type: CREATE_REPLY_SUCCESS,
        payload: {
            data: {...reply}
        }
    }
}

export function createReplyFailure(err) {
    return {
        type: CREATE_REPLY_FAILURE,
        payload: {
            error: err,
        },
    }
}

export function fetchReplies(url, request, dispatch){
    const success = (res) => dispatch(fetchRepliesSuccess(res));
    const failure = (err) => dispatch(fetchRepliesFailure(err.message));
    makeHttpRequest(url, request, success, failure);

}


export function fetchRepliesStarted() {
    return {
        type: FETCH_REPLIES_STARTED,

    }
}

export function fetchRepliesSuccess(replies){
    return{
        type: FETCH_REPLIES_SUCCESS,
        payload: {
            data: {...replies}
        },
    };
}

export function fetchRepliesFailure(message){
    return {
        type: FETCH_REPLIES_FAILURE,
        payload: {
            error: message,
        },
    };
}

export function fetchMetrics(url, request, dispatch){
    const success = (res) => dispatch(fetchMetricsSuccess(res));
    const failure = (err) => dispatch(fetchMetricsFailure(err.message));
    makeHttpRequest(url, request, success, failure);

}


export function fetchMetricsStarted() {
    return {
        type: FETCH_METRICS_STARTED,

    }
}

export function fetchMetricsSuccess(metrics){
    return{
        type: FETCH_METRICS_SUCCESS,
        payload: {
            data: {...metrics}
        },
    };
}

export function fetchMetricsFailure(message){
    return {
        type: FETCH_METRICS_FAILURE,
        payload: {
            error: message,
        },
    };
}

export function fetchUsers(url, request, dispatch){
    const success = (res) => dispatch(fetchUsersSuccess(res));
    const failure = (err) => dispatch(fetchUsersFailure(err.message));
    makeHttpRequest(url, request, success, failure);
}


export function fetchUsersStarted() {
    return {
        type: FETCH_USERS_STARTED,

    }
}

export function fetchUsersSuccess(users){
    return{
        type: FETCH_USERS_SUCCESS,
        payload: {
            data: {...users}
        },
    };
}

export function fetchUsersFailure(message){
    return {
        type: FETCH_USERS_FAILURE,
        payload: {
            error: message,
        },
    };
}

export function fetchUser(url, request, dispatch){
    const success = (res) => dispatch(fetchUserSuccess(res));
    const failure = (err) => dispatch(fetchUserFailure(err.message));
    makeHttpRequest(url, request, success, failure);

}


export function fetchUserStarted() {
    return {
        type: FETCH_USER_STARTED,

    }
}

export function fetchUserSuccess(user){
    return{
        type: FETCH_USER_SUCCESS,
        payload: {
            data: {...user}
        },
    };
}

export function fetchUserFailure(message){
    return {
        type: FETCH_USER_FAILURE,
        payload: {
            error: message,
        },
    };
}

export function deleteUser(url, request, dispatch) {
    const success = (res) => dispatch(deleteUserSuccess(res));

    const failure = (err) => dispatch(deleteUserFailure(err.message));

    makeHttpRequest(url, request, success, failure);
}

export function deleteUserStarted() {
    return {
        type: DELETE_USER_STARTED,

    }
}

export function deleteUserSuccess(user) {
    return {
        type: DELETE_USER_SUCCESS,
        payload: {
            data: {...user}
        }
    }
}

export function deleteUserFailure(err) {
    return {
        type: DELETE_USER_FAILURE,
        payload: {
            error: err,
        },
    }
}

export function updateUserPassword(url, request, dispatch) {
    const success = (res) => dispatch(editFormSuccess(res));

    const failure = (err) => dispatch(editFormFailure(err.message));

    makeHttpRequest(url, request, success, failure);
}

export function updateUserPasswordStarted() {
    return {
        type: UPDATE_PASSWORD_STARTED,

    }
}

export function updateUserPasswordSuccess(pass) {
    return {
        type: UPDATE_PASSWORD_SUCCESS,
        payload: {
            data: {...pass}
        }
    }
}

export function updateUserPasswordFailure(err) {
    return {
        type: UPDATE_PASSWORD_FAILURE,
        payload: {
            error: err,
        },
    }
}

export function fetchReference(url, request, dispatch){
    const success = (res) => dispatch(fetchUserSuccess(res));
    const failure = (err) => dispatch(fetchUserFailure(err.message));
    makeHttpRequest(url, request, success, failure);

}


export function fetchReferenceStarted() {
    return {
        type: FETCH_REFERENCE_STARTED,

    }
}

export function fetchReferenceSuccess(user){
    return{
        type: FETCH_REFERENCE_SUCCESS,
        payload: {
            data: {...user}
        },
    };
}

export function fetchReferenceFailure(message){
    return {
        type: FETCH_REFERENCE_FAILURE,
        payload: {
            error: message,
        },
    };
}

export function fetchReferences(url, request, dispatch){
    const success = (res) => dispatch(fetchUserSuccess(res));
    const failure = (err) => dispatch(fetchUserFailure(err.message));
    makeHttpRequest(url, request, success, failure);

}


export function fetchReferencesStarted() {
    return {
        type: FETCH_REFERENCE_STARTED,

    }
}

export function fetchReferencesSuccess(user){
    return{
        type: FETCH_REFERENCE_SUCCESS,
        payload: {
            data: {...user}
        },
    };
}

export function fetchReferencesFailure(message){
    return {
        type: FETCH_REFERENCE_FAILURE,
        payload: {
            error: message,
        },
    };
}

