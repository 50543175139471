import React from 'react'

const TextBlock = ({ id, placeholder, label, formId }) => {

  return (
    <>
      <div className="mb-6">
      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{label}</label>
        <div className="mt-1 w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:outline-none focus:border-blue-500 block p-2.5 h-fit">
          {placeholder}
        </div>
      </div>
    </>

  );
};

export default TextBlock;





