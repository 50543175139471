import React, { useContext } from 'react'
import Context from '../../context/Context';


const StarRating = ({ id, label}) => {
    const { handleChange } = useContext(Context)

    return (
        <>

<div className="m-2 mr-5 w-full bg-white rounded shadow border p-4">
                <div className="mb-6">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{label}</label>
                    {[1, 2, 3, 4, 5].map((option) => (
                        <div key={option} className="mt-1">
                            <input
                                type="radio"
                                id={option}
                                name={id}
                                value={option}
                                onChange={event => handleChange(id, event)}
                                className="mr-2"
                            />
                            <label htmlFor={option}>
                                {[...Array(option)].map(() => <span className="text-yellow-500">⭐️</span>)}
                            </label>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default StarRating