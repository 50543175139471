import React, { useContext, useEffect, useState } from 'react';
import { fetchReplies } from '../../context/forms/FormsAction';
import Context from '../../context/Context';
import { useParams } from 'react-router-dom';
import { URL } from "../../service/Service";
import Breadcrumb from '../../components/Breadcrumb';
import Spinner from '../../components/Spinner';
import Header from '../../components/TitleAndSubtitle';


const Replies = () => {
    const { idform } = useParams()
    const { state, dispatch } = useContext(Context);
    const { loading, error, data } = state.replies;
    const url = `${URL}/api/forms/${idform}/replies`;
    const [replies, setReplies] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedReply, setSelectedReply] = useState(null);

    //////////////////////SEARCH AND PAGINATION///////////////////////////////


    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        const isSmallScreen = window.innerHeight <= 600;
        setRowsPerPage(isSmallScreen ? 5 : 10);
    }, []);

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
        setCurrentPage(1); // reset page number when search term changes
    };

    const filteredformsMap = replies.filter((item) => {
        return item.id.toLowerCase().includes(searchTerm.toLowerCase())
    });

    const lastRowIndex = currentPage * rowsPerPage;
    const firstRowIndex = lastRowIndex - rowsPerPage;
    const currentRows = filteredformsMap.slice(firstRowIndex, lastRowIndex);
    const totalPages = Math.ceil(filteredformsMap.length / rowsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const pageNumbers = [];

    // show the first page number
    pageNumbers.push(1);

    for (let i = currentPage - 2; i <= currentPage + 2; i++) {
        if (i > 1 && i < totalPages) {
            pageNumbers.push(i);
        }
    }

    // show the last page number
    pageNumbers.push(totalPages);

    ////////////////////////////////////////////////////////////////////////////////


    const handleReplyClick = (reply) => {
        setSelectedReply(reply);
        setShowModal(true);
    }

    const pages = [
        {
            name: 'Dashboard',
            href: `/admin/dashboard/`,
            current: false
        },
        {
            name: 'Respostas',
            href: `/admin/forms/${idform}/replies`,
            current: true
        },
    ];

    useEffect(() => {
        fetchReplies(url, {}, dispatch);
        setReplies(Object.values(data).reverse())
    }, [dispatch, idform]);

    useEffect(() => {
        if (data && Object.values(data).length > 0) {
            setReplies(Object.values(data).reverse());
        }
    }, [data]);

    useEffect(() => {
        if (data && Object.values(data).length > 0) {
            setReplies(Object.values(data).reverse());
        }
    }, [data]);



    if (loading === true) {
        return (
            <Spinner></Spinner>
        )
    }

    function downloadCSV(array) {
        const csvRows = [];

        array.forEach(reply => {
            const timestamp = reply.timestamp;
            const formID = reply.formID;
            const paymentMethod = reply.reference.referencePaymentMethod;
            const amount = reply.reference.amount;
            const status = reply.reference.referenceStatus;
            const row = [];

            reply.replyFields.forEach((field) => {
                row.push(field.label, field.answer);
            });

            csvRows.push([timestamp, formID, paymentMethod, amount, status, ...row]);
        });

        const csv = csvRows.map(row => row.map(val => `"${val}"`).join(',')).join('\n');

        const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

        const link = document.createElement('a');
        link.setAttribute('href', window.URL.createObjectURL(csvData));
        link.setAttribute('download', 'replies.csv');
        link.style.display = 'none';
        document.body.appendChild(link);

        link.click();
    }



    return (

        <div className='p-5'>
            <Breadcrumb pages={pages} />
            <div className='mb-5'></div>
            <div className="flex flex-col md:flex-row justify-between items-center">
                <Header title="Respostas" />
                <button className='mt-5 md:mt-0 bg-green-cl hover:bg-opacity-80 text-white py-2 px-4 rounded' onClick={() => downloadCSV(replies)}>Exportar CSV</button>
            </div>
            <div className="my-4">
                <input className="border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    type="text" placeholder="Procurar por Identificador" value={searchTerm} onChange={handleSearch} />
            </div>
            <div className="overflow-auto rounded-md">
                <table className="table-auto w-full border">
                    <thead className=''>
                        <tr className="bg-green-cl">
                            <th className="px-4 py-2 text-left text-gray-100">Identificador</th>
                            <th className="px-4 py-2 text-left text-gray-100">Data da Resposta</th>
                            <th className="px-4 py-2 text-left text-gray-100">Método de Pagamento</th>
                            <th className="px-4 py-2 text-left text-gray-100">Valor</th>
                            <th className="px-4 py-2 text-left text-gray-100">Estado</th>
                            <th className="px-4 py-2 text-left text-gray-100">Respostas</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentRows.map((item) => (
                            <tr key={item.id} className="border-t border-gray-200">
                                <td className="px-4 py-1">{item.id}</td>
                                <td className="px-4 py-1">{item.timestamp}</td>
                                <td className="px-4 py-1">
                                    {item.reference.referencePaymentMethod === 'MULTIBANCO' && 'Multibanco'}
                                    {item.reference.referencePaymentMethod === 'MBWAY' && 'Mb Way'}
                                    {item.reference.referencePaymentMethod === 'CREDIT_CARD' && 'Cartão de Crédito'}
                                </td>
                                <td className="px-4 py-1">
                                    {item.reference.amount}
                                </td>
                                <td className="px-4 py-1" style={{ color: item.reference.referenceStatus === 'PAID' ? 'green' : item.reference.referenceStatus === 'UNPAID' ? 'red' : item.reference.referenceStatus === 'CANCELLED' ? 'black' : item.reference.referenceStatus === 'REFUNDED' ? 'black' : '', fontWeight: 'bold' }}>
                                    {item.reference.referenceStatus === 'PAID' && 'Pago'}
                                    {item.reference.referenceStatus === 'UNPAID' && 'Não Pago'}
                                    {item.reference.referenceStatus === 'CANCELLED' && 'Cancelado'}
                                    {item.reference.referenceStatus === 'REFUNDED' && 'Reembolsado'}
                                </td>
                                <td className="px-4 py-1">
                                    <button className="" onClick={() => handleReplyClick(item)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 mr-1">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                        </svg>
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="flex justify-end mt-4">
                {totalPages > 1 && (
                    <nav>
                        <ul className="inline-flex">
                            {pageNumbers.map((number) => (
                                <li key={number} className={currentPage === number ? "px-2 py-1 bg-green-cl text-white rounded-md mx-1" : "px-2 py-1 bg-gray-200 text-gray-700 rounded-md mx-1"}>
                                    <button onClick={() => handlePageChange(number)} className="focus:outline-none">
                                        {number}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </nav>
                )}
            </div>
            {showModal && selectedReply && (
                <div className="fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-75 z-50 flex justify-center items-center">
                    <div className="rounded shadow bg-gray-100 p-5 relative">
                        <button className="absolute top-0 right-0 p-2" onClick={() => setShowModal(false)}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                        <h2 className="mb-3 text-center text-2xl font-bold">Respostas</h2>
                        <table className="table-auto w-full border">
                            <thead className=''>
                                <tr className="bg-green-cl">
                                    <th className="px-4 py-2 text-left text-gray-100">Campo</th>
                                    <th className="px-4 py-2 text-left text-gray-100">Resposta</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedReply.replyFields.map((content) => (
                                    <tr key={content.label} className="border-t border-gray-200 justify-center text-center">
                                        <td className="px-4 py-1">{content.label}</td>
                                        <td className="px-4 py-1">{content.answer}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                    </div>
                </div>
            )}
        </div>
    );
};


export default Replies





