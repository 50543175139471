import React from 'react'

const Divider = () => {

  return (
    <>
      <div className="mb-6">
        <hr className="h-px my-8 bg-black border-0 dark:bg-gray-700" />
      </div>
    </>

  );
};

export default Divider




