import { Dialog, DialogTitle, DialogActions, Button, IconButton } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import useStyles from '../styles/styles';

const LogoutButtonWhite = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const history = useNavigate();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleLogout = () => {
    sessionStorage.removeItem('jwt');
    history('/login');
  };

  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        <LogoutIcon className={classes.whiteIcon} />
      </IconButton>
      <Dialog open={open} onClose={handleClose} >
        <DialogTitle>Tem a certeza que quer sair?</DialogTitle>
        <DialogActions>
          <button onClick={handleClose} className="flex w-full justify-center rounded-md border border-transparent bg-green-cl py-2 px-4  font-normal text-white shadow-sm hover:opacity-80 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >Cancelar</button>
          <button onClick={handleLogout} className="flex w-full justify-center rounded-md border border-transparent bg-green-cl py-2 px-4  font-normal text-white shadow-sm hover:opacity-80 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >Log out</button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default LogoutButtonWhite;