import React, { useState } from 'react';
import axios from 'axios';
import { URL } from '../../service/Service';

const ResetPassword = () => {
    const [userEmail, setUserEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${URL}/api/resetpassword`, { userEmail });
            console.log('Response:', response.data);
            setMessage('Enviado email com o link de recuperar palavra-passe');
            setError('');
        } catch (error) {
            console.log('Error:', error);
            if (error.response && error.response.data) {
                setError(error.response.data);
            } else {
                setError('An error occurred while trying to reset the password.');
            }
            setMessage('');
        }
    };

    return (
        <div className="flex min-h-screen items-center flex-col justify-center py-12 bg-eupago-bg sm:px-6 lg:px-8">
            <div className="mx-auto sm:mx-auto sm:w-full sm:max-w-md lg:max-w-lg xl:max-w-xl">
                <div className="bg-white justify-center py-12 px-20 shadow sm:rounded-lg sm:px-12 box-border">
                    <div className="sm:mx-auto sm:w-full sm:max-w-md">
                        <img
                            className="mx-auto h-12 w-auto"
                            src="https://sandbox.eupago.pt/backoffice/files/assets/images/auth/rebranding/Logo.png"
                            alt='Eupago'
                        />
                    </div>
                    <div className='m-5'></div>
                    <form className="space-y-6" onSubmit={handleSubmit}>
                        <div>
                            <label htmlFor="email" className="block text-sm font-bold text-gray-700">
                                Email
                            </label>
                            <div className="mt-1">
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    required
                                    value={userEmail}
                                    onChange={e => setUserEmail(e.target.value)}
                                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:ring-green-cl focus:outline-none focus:border-blue-leve sm:text-sm"
                                />
                            </div>
                        </div>
                        <div className="flex items-center justify-between"></div>
                        <div>
                            <button
                                type="submit"
                                className="flex w-full justify-center rounded-md border border-transparent bg-green-claro py-2 px-4 font-bold font-medium text-white shadow-sm hover:bg-green-cl focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                Reset da palavra-passe
                            </button>
                        </div>
                        {message && <div className="text-sm text-center text-green-600">{message}</div>}
                        {error && <div className="text-sm text-center text-red-600">{error}</div>}
                        <div className="text-sm text-center">
                            <a href="/login" className="font-medium text-indigo-600 hover:text-indigo-500">
                                Voltar à página de login
                            </a>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default ResetPassword;
