import React from 'react';
import Price from '../clientFormElements/Price';


const PriceElement = ({ formElement: { element_option, element_type, element_value, id, label, links, photo, placeholder, number, selection, cost, required } }) => {
    switch (element_type) {
        case 'price':
            return (
                <>
                    <Price
                        id={id}
                        label={label}
                        placeholder={placeholder}
                        element_value={element_value}
                        
                    />

                </>)
        default:
            return null;
    }
}

export default PriceElement