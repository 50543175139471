import React from 'react'


const Input = ({ placeholder, label, required, onInputChange, regex }) => {

    const handleInputChange = event => {
        onInputChange(label, event.target.value);
    };


    return (
            <div className="mb-6">
                <label className="block mb-2 text-sm font-medium text-gray-900">
                    {label} {required && <span className="text-red-500 text-xl"> *</span>}
                </label>
                <input
                    id="input"
                    className="border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder={placeholder}
                    {...(required ? { required: true } : {})}
                    onChange={handleInputChange}
                />
            </div>
    )
}

export default Input